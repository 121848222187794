import React from 'react'
import {makeStyles} from '@material-ui/styles';


const useStyles = makeStyles({
    circle1:{
        position: 'absolute',
        width: '70%',
        height: '70%',
        right: '22%',
        top: '350%',
     
        
        border: '2px solid #04514C',
        borderRadius: '100%',
        
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            margin: '20px auto',
            width: '40%',
            height: '80%',
            right: '53%',
            top: '150%',
            border: '5px solid #04514C',
        },
    },

    circle2:{
        position: 'absolute',
        width: '70%',
        height: '70%',
        right: '26%',
        top: '355%',
    
        border: '2px solid #04514C',
        borderRadius: '100%',
        
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            margin: '20px auto 20px',
            width: '40%',
            height: '80%',
            right: '58%',
            top: '155%',
            border: '5px solid #04514C',
        },
    }
  
})


const LeftCircle = () => {

    const classes = useStyles()
  return (
    <div>
        <div className={classes.circle1}></div>
        <div className={classes.circle2}></div>
    </div>
  )
}

export default LeftCircle