import {useState} from 'react';
// import { useNavigate } from 'react-router-dom';
import {  Card, CardContent, CardMedia, CardActions, Button,Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';



export const TestimonialData = [
    {
        content : "A patient testimony is a written or spoken statement from a patient about their experience with a healthcare provider or treatment. This can include the patient's overall satisfaction with the care they received, the effectiveness of the treatment, and any improvements in their condition or quality of life. Patient testimonies can be a valuable resource for other patients considering the same provider or treatment, as well as for healthcare providers looking for feedback on their services",
        name : 'Mark Pude',
    },
    {
        content : "A patient testimony is a written or spoken statement from a patient about their experience with a healthcare provider or treatment. This can include the patient's overall satisfaction with the care they received, the effectiveness of the treatment, and any improvements in their condition or quality of life. Patient testimonies can be a valuable resource for other patients considering the same provider or treatment, as well as for healthcare providers looking for feedback on their services",
        name : 'Mark Pude',
    },
    {
        content : "A patient testimony is a written or spoken statement from a patient about their experience with a healthcare provider or treatment. This can include the patient's overall satisfaction with the care they received, the effectiveness of the treatment, and any improvements in their condition or quality of life. Patient testimonies can be a valuable resource for other patients considering the same provider or treatment, as well as for healthcare providers looking for feedback on their services",
        name : 'Mark Pude',
    },
    {
        content : "A patient testimony is a written or spoken statement from a patient about their experience with a healthcare provider or treatment. This can include the patient's overall satisfaction with the care they received, the effectiveness of the treatment, and any improvements in their condition or quality of life. Patient testimonies can be a valuable resource for other patients considering the same provider or treatment, as well as for healthcare providers looking for feedback on their services",
        name : 'Mark Pude',
    },
]


export default function TestimonialCard({ name, content }) {

  return (
    <Card sx={{ maxWidth: {xs:300, sm:500}, margin: {xs:'20px auto', sm:'20px 0'}, borderRadius: '20px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',}}>
          
        <CardContent>
            <Typography variant="body2"  style={{color:'#6E6D6D'}}>
                {content}
            </Typography>
              
            <Typography color="text." style={{ textAlign: 'right', fontWeight: 'bold' }}>- {name}</Typography>
        </CardContent>
      
       
          
    </Card>
  );
}