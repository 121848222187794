
// import { useNavigate } from 'react-router-dom';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import { Button, CardActionArea, CardActions } from '@mui/material';
// import { styled } from '@mui/material/styles';

// const StyledButton = styled(Button)(() => ({
//     fontFamily: 'Poppins',
//     color: 'white',
//     marginTop: '20px',
//     fontWeight: 'bolder',
//     backgroundColor: '#04514C',
//     width: '135px',
//     height: '30px',
//     marginLeft: '50%',
//   ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
//     width: '230px',
//     height: '40px',
//   },
// }));

// export default function WhatWeDo({ image, body, link }) {
    
//     const navigate = useNavigate();
//     return (
//         <Card sx={{ maxWidth: {xs:300, sm:300}, margin: {xs:'20px auto', sm:'20px 0'}, borderRadius: '20px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',}}>
//             <CardActionArea>
//                 <CardMedia
//                 component="img"
//                 height="140"
//                 image={image}
//                 alt="green iguana"
//                 />
//                 <CardContent>
//                 <Typography variant="body2" color="text.secondary">
//                     {body}
//                 </Typography>
//                 </CardContent>
//             </CardActionArea>
//             <CardActions>
//                 <StyledButton size="small" color="primary" onClick={()=>navigate(link)}>
//                     <span style={{margin : '5px 2px 0 0'}}>
//                         <svg width="7" height="15" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M0.534217 0.526183C0.364872 0.691892 0.230521 0.888723 0.138853 1.10541C0.0471848 1.3221 0 1.55438 0 1.78897C0 2.02356 0.0471848 2.25584 0.138853 2.47253C0.230521 2.68922 0.364872 2.88605 0.534217 3.05176L7.62193 10.0016L0.534217 16.9514C0.192659 17.2863 0.000773495 17.7405 0.000773495 18.2141C0.000773495 18.6878 0.192659 19.142 0.534217 19.4769C0.875774 19.8119 1.33903 20 1.82206 20C2.3051 20 2.76835 19.8119 3.10991 19.4769L11.4946 11.2554C11.6639 11.0897 11.7983 10.8928 11.89 10.6762C11.9816 10.4595 12.0288 10.2272 12.0288 9.9926C12.0288 9.75802 11.9816 9.52573 11.89 9.30904C11.7983 9.09236 11.6639 8.89553 11.4946 8.72982L3.10991 0.508271C2.41575 -0.17238 1.24664 -0.17238 0.534217 0.526183Z" fill="white"/>
//                         </svg>
//                         <svg width="7" height="15" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
//                             <path d="M0.534217 0.526183C0.364872 0.691892 0.230521 0.888723 0.138853 1.10541C0.0471848 1.3221 0 1.55438 0 1.78897C0 2.02356 0.0471848 2.25584 0.138853 2.47253C0.230521 2.68922 0.364872 2.88605 0.534217 3.05176L7.62193 10.0016L0.534217 16.9514C0.192659 17.2863 0.000773495 17.7405 0.000773495 18.2141C0.000773495 18.6878 0.192659 19.142 0.534217 19.4769C0.875774 19.8119 1.33903 20 1.82206 20C2.3051 20 2.76835 19.8119 3.10991 19.4769L11.4946 11.2554C11.6639 11.0897 11.7983 10.8928 11.89 10.6762C11.9816 10.4595 12.0288 10.2272 12.0288 9.9926C12.0288 9.75802 11.9816 9.52573 11.89 9.30904C11.7983 9.09236 11.6639 8.89553 11.4946 8.72982L3.10991 0.508271C2.41575 -0.17238 1.24664 -0.17238 0.534217 0.526183Z" fill="white"/>
//                         </svg>
//                     </span> See more
//                 </StyledButton>
//             </CardActions>
//         </Card>
//   );
// }


import {useState} from 'react';
// import { useNavigate } from 'react-router-dom';
import {  Card, CardContent, CardMedia, CardActions, Button,Typography,Dialog,DialogTitle,DialogContent,} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';




const StyledButton = styled(Button)(() => ({
    fontFamily: 'Poppins',
    color: 'white',
    marginTop: '20px',
    fontWeight: 'bolder',
    backgroundColor: '#04514C',
    width: '135px',
    height: '30px',
    marginLeft: '50%',
    '&:hover': {
        backgroundColor : '#04514C',
    },
  ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    width: '230px',
    height: '40px',
  },
}));


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


export default function WhatWeDoCard({ image, body }) {
    
    // function that handle the see more card.

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(!open);
    };
    const handleClose = () => {
        setOpen(!open);
    };

  return (
    <Card onClick={handleClickOpen} sx={{ maxWidth: {xs:300, sm:300}, margin: {xs:'20px auto', sm:'20px 0'}, borderRadius: '20px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',}}>
        <CardMedia
            component="img"
            height="194"
            image={image}
            alt="Paella dish"
        />
          
        <CardContent>
            <Typography variant="body2" color="text.secondary">
            {body}
            </Typography>
        </CardContent>
      
        <CardActions disableSpacing>
              
            <StyledButton size="small" color="primary" onClick={handleClickOpen}>
                <span style={{margin : '5px 2px 0 0'}}>
                    <svg width="7" height="15" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.534217 0.526183C0.364872 0.691892 0.230521 0.888723 0.138853 1.10541C0.0471848 1.3221 0 1.55438 0 1.78897C0 2.02356 0.0471848 2.25584 0.138853 2.47253C0.230521 2.68922 0.364872 2.88605 0.534217 3.05176L7.62193 10.0016L0.534217 16.9514C0.192659 17.2863 0.000773495 17.7405 0.000773495 18.2141C0.000773495 18.6878 0.192659 19.142 0.534217 19.4769C0.875774 19.8119 1.33903 20 1.82206 20C2.3051 20 2.76835 19.8119 3.10991 19.4769L11.4946 11.2554C11.6639 11.0897 11.7983 10.8928 11.89 10.6762C11.9816 10.4595 12.0288 10.2272 12.0288 9.9926C12.0288 9.75802 11.9816 9.52573 11.89 9.30904C11.7983 9.09236 11.6639 8.89553 11.4946 8.72982L3.10991 0.508271C2.41575 -0.17238 1.24664 -0.17238 0.534217 0.526183Z" fill="white"/>
                    </svg>
                    <svg width="7" height="15" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.534217 0.526183C0.364872 0.691892 0.230521 0.888723 0.138853 1.10541C0.0471848 1.3221 0 1.55438 0 1.78897C0 2.02356 0.0471848 2.25584 0.138853 2.47253C0.230521 2.68922 0.364872 2.88605 0.534217 3.05176L7.62193 10.0016L0.534217 16.9514C0.192659 17.2863 0.000773495 17.7405 0.000773495 18.2141C0.000773495 18.6878 0.192659 19.142 0.534217 19.4769C0.875774 19.8119 1.33903 20 1.82206 20C2.3051 20 2.76835 19.8119 3.10991 19.4769L11.4946 11.2554C11.6639 11.0897 11.7983 10.8928 11.89 10.6762C11.9816 10.4595 12.0288 10.2272 12.0288 9.9926C12.0288 9.75802 11.9816 9.52573 11.89 9.30904C11.7983 9.09236 11.6639 8.89553 11.4946 8.72982L3.10991 0.508271C2.41575 -0.17238 1.24664 -0.17238 0.534217 0.526183Z" fill="white"/>
                    </svg>
                </span> See more
            </StyledButton>
              
        </CardActions>
          
        <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Modal title
        </BootstrapDialogTitle>
        <DialogContent dividers >
          <Typography gutterBottom>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over
            medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring
            occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a
            large plate and set aside, leaving chicken and chorizo in the pan. Add
            pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook,
            stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography>
        </DialogContent>
        
      </BootstrapDialog>
          
    </Card>
  );
}









