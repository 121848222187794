import React from 'react'
import {makeStyles} from '@material-ui/styles';


const useStyles = makeStyles({
    circle1: {
        display:'none',
        position: 'absolute',
        width: '70%',
        height: '70%',
        left: '22%',
        top: '570%',
     
        
        border: '2px solid #04514C',
        borderRadius: '100%',
        
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            display:'block',
            margin: '20px auto',
            width: '40%',
            height: '80%',
            left: '53%',
            top: '235%',
            border: '5px solid #04514C',
        },
    },

    circle2: {
        display:'none',
        position: 'absolute',
        width: '70%',
        height: '70%',
        left: '26%',
        top: '575%',
    
        border: '2px solid #04514C',
        borderRadius: '100%',
        
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            display:'block',
            margin: '20px auto 20px',
            width: '40%',
            height: '80%',
            left: '58%',
            top: '240%',
            border: '5px solid #04514C',
        },
    }
  
})


const MiddleCircle = () => {

    const classes = useStyles()
  return (
    <div>
        <div className={classes.circle1}></div>
        <div className={classes.circle2}></div>
    </div>
  )
}

export default MiddleCircle