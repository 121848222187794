import * as React from 'react';
import {useNavigate} from 'react-router'
import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {  Card, CardHeader, CardMedia, CardActions, Button, Typography, Avatar, IconButton } from '@mui/material';



const StyledButton = styled(Button)(() => ({
    fontFamily: 'Poppins',
    color: 'white',
    marginTop: '0',
    fontWeight: 'bolder',
    backgroundColor: '#04514C',
    width: '120px',
    height: '25px',
    marginLeft: '75%',
    '&:hover': {
        backgroundColor : '#04514C',
    },
  ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    width: '120px',
    height: '30px',
  },
}));

export default function DoctorsCard({ image, name, link}) {


    const navigate = useNavigate();
  return (
    <Card sx={{ maxWidth: {xs:300, sm:350}, margin: {xs:'20px auto', sm:'20px 0'}, borderRadius: '20px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)', }}>
      <CardHeader
        avatar={
          <Avatar sx={{width:'109px', height:'109px'  }}>
            <img src={image} alt={image}/>
          </Avatar>
        }
        title={name}
        subheader="September 14, 2016"
      />
   
      <CardActions disableSpacing>
        <StyledButton size="small" color="primary" onClick={()=>navigate('/contact')}>
                  Connect
       </StyledButton>
      </CardActions>
      
    </Card>
  );
}