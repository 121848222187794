import { useEffect, useContext } from 'react';
import { useNavigate} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Button from '@mui/material/Button';
import Sidebar from './Sidebar';
import heart from './Images/heart.png';
import sugar from './Images/sugar.jpg';
import lungs from './Images/lungs.png';
import BP from './Images/bp.jpg';
import ReminderImage from './Images/reminderImg.png';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import axiosInstance from '../../api/axios';
import StateContext from '../../context/StateContext';
import { useImmerReducer } from 'use-immer';




const useStyles = makeStyles({
  container: {
    margin: '0 auto',
    width: '100%',
    display: 'block',
    padding: '0',
    minHeight: '75vh',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        margin: '20px auto',
        padding: '0',
        width: '80%',
        minHeight: '50vh',
        display: 'flex',
    },
  },

  section1: {
    display:'flex',
    width: '100%',
    justifyContent: 'space-around',
    padding: '20px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },

  section2: {
    display:'block',
    minHeight:'30vh',
    padding: '20px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      justifyContent: 'space-between',
      minHeight:'20vh',
    },
  },

  section3: {
    // display:'block',
    minHeight:'30vh',
    padding: '20px',
    margin: ' 0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      justifyContent: 'space-between',
      minHeight:'20vh',
    },
  },

  ReminderImageDiv: {
    position: 'absolute',
    top: '65%',
    left: '55%',
    width: '40%',
  
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      position: 'absolute',
      top: '14%',
      left: '75%',
      width:'40%',
    },
  },

  Image: {
    width: '100%',
    height: '25vh',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      height: '240px',
      width:'200px',
    },
  },
  
})

const StyledButton = styled(Button)(() => ({
  fontFamily: 'Poppins',
  backgroundColor: '#04514C',
  color: '#FFF',
  marginTop: '40px',
  fontWeight: 'bolder',
  ':hover': {
        backgroundColor: 'white',
        color: '#04514C',
        borderRadius: '0px 10px',
  },
}));


const Dashboard = () => {
    const classes = useStyles()
  const navigate = useNavigate();

   const GlobalState = useContext(StateContext);
  const GlobalUser = JSON.parse(GlobalState.userInfo);

  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

  let todaysDate = `${new Date().getFullYear()}-${String(new Date().getMonth()+1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`
  let dayBefore = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate() - 1).padStart(2, '0')}`
  let dayAfter = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate() + 1).padStart(2, '0')}`
  let twoDaysAfter = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate() + 2).padStart(2, '0')}`



  const initialState = {
    AppointmentData: [],
    medicalReport: {
      heartRate : "",
      bloodPressure : "",
      sugarLevel : "",
      lungCapacity : "",
      pcv : "",
      hcv : "",
      blood_group : "",
      genotype : "",
      ecg : "",
      asthma : "",
      covid : "",
      urine : "",
      sputum : "",
    },
    userDetails: {
      profilePicture: ""
    }
  }


  const reducerFunction = (draft, action) => {
    switch (action.type) {
      case 'catchAppointmentData':
        draft.AppointmentData = action.AppointmentValue
        break;
      case 'catchMedicalReport':
        draft.medicalReport.heartRate = action.medicalReportValue.heartRate
        draft.medicalReport.bloodPressure = action.medicalReportValue.bloodPressure
        draft.medicalReport.sugarLevel = action.medicalReportValue.sugarLevel
        draft.medicalReport.lungCapacity = action.medicalReportValue.lungCapacity
        draft.medicalReport.pcv = action.medicalReportValue.pcv
        draft.medicalReport.hcv = action.medicalReportValue.hcv
        draft.medicalReport.blood_group = action.medicalReportValue.blood_group
        draft.medicalReport.genotype = action.medicalReportValue.genotype
        draft.medicalReport.ecg = action.medicalReportValue.ecg
        draft.medicalReport.asthma = action.medicalReportValue.asthma
        draft.medicalReport.covid = action.medicalReportValue.covid
        draft.medicalReport.urine = action.medicalReportValue.urine
        draft.medicalReport.sputum = action.medicalReportValue.sputum
        break;
      case 'catchUserDetails':
        draft.userDetails.profilePicture = action.userDetailsValue.profile_picture
        break;
      default:
        break;
    }
  }

  useEffect(() => {
      
      const getProfile = async () => {

        try {
            const response = await axiosInstance.get(`/auth/profile/user/get_profile/?username=${GlobalUser.user}`, {
                headers: {
                   ' Content-Type': 'application/json',
                    Authorization: `Bearer ${GlobalState.userAccessToken}`,
                }
            })
          dispatch({ type: "catchUserDetails", userDetailsValue: response.data[0] })
        
          // setUserDetails(response.data[0])
        } catch (error) {
            console.log(error);
        }
      }
      
      getProfile()
    
  }, []);



  useEffect(() => {
      
      const getMedicalReport = async () => {

        try {
            const response = await axiosInstance.get(`/auth/profile/user/get_medical_report/?username=${GlobalUser.user}`, {
                headers: {
                   ' Content-Type': 'application/json',
                    Authorization: `Bearer ${GlobalState.userAccessToken}`,
                }
            })
          dispatch({ type: "catchMedicalReport", medicalReportValue: response.data[0] })
          // setUserDetails(response.data[0])
        } catch (error) {
            console.log(error);
        }
      }
      
      getMedicalReport()
    
  }, []);



  useEffect(() => {
    const GetAppointment = async() => {
      try {
        const response = await axiosInstance.get(`/appointments/appointments_for/?username=${GlobalUser.user}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${GlobalState.userAccessToken}`
          }
        })
        dispatch({type: 'catchAppointmentData', AppointmentValue : response.data})
      } catch(error) {
        console.log(error);
      }
    }
    GetAppointment()
    
  }, [])

  const [state, dispatch] = useImmerReducer(reducerFunction, initialState)

  let currentAppointment = 0
  let dayBeforeAppointment = 0
  let dayAfterAppointment = 0
  let twoDaysAfterAppointment = 0
  state.AppointmentData.map(data => {
    if (data.date === todaysDate) {
      currentAppointment += 1
      return currentAppointment
    }

    if (data.date === dayBefore) {
      dayBeforeAppointment += 1
      return dayBeforeAppointment
    }

    if (data.date === dayAfter) {
      dayAfterAppointment += 1
      return dayAfterAppointment
    }

    if (data.date === twoDaysAfter) {
      twoDaysAfterAppointment += 1
      return twoDaysAfterAppointment
    }
    
  })

  const MedicalReportData = [
    {
      name: 'PCV',
      result : state.medicalReport.pcv,  
    },
    {
        name : 'HCV',
        result : state.medicalReport.hcv, 
    },
    {
        name : 'Blood Group',
        result: state.medicalReport.blood_group,
    },
    {
        name : 'Genotype',
        result: state.medicalReport.genotype,
    },
    {
        name : 'E.C.G',
        result: state.medicalReport.ecg,
    },
    {
        name : 'Asthma',
        result: state.medicalReport.asthma,
    },
    {
        name : 'Covid-19 Vaccine',
        result: state.medicalReport.covid,
    },
    {
        name : 'Urine',
        result: state.medicalReport.urine,
    },
    {
        name : 'Sputum Test',
        result: state.medicalReport.sputum,
    },
]

  

  return (
    <div className={classes.container}>
      <div style={{minWidth: '20%', marginBottom:'50px'}}>
        <Sidebar  />
      </div>

      <div style={{width: '100%', backgroundColor:'#95A5A6', minHeight: '50vh',}}>
        
        <div className={classes.section1}>
          <div style={{width:'55%'}}> 
              <h5>Dashborad</h5>
            <small>Welcome, { GlobalUser.fName}</small>
          </div>

          <div style={{width:'40%', display: 'flex', justifyContent:'flex-end',}}>
              <div style={{fontSize:'1.5rem', padding:'0 20px'}}><i style={{color:'#04514C'}} className="fa fa-bell" aria-hidden="true"></i></div>
              <div onClick={()=>navigate('/profile')} style={{cursor:'pointer'}}><img src={state.userDetails.profilePicture} alt="Avatar" className="img-fluid" style={{width: "40px",height:'40px', borderRadius:'100%'}} /></div>
          </div>
        </div>



        <Grid container className = {classes.section2}>
          <Grid item xs={12} sm={6.5} sx={{margin:{xs:'0 0 10px', sm:'0'}}}>
            <div>
              <Grid container sx={{ backgroundColor: '#fff', borderRadius:'20px', margin:{xs:'0', sm:'0 0 50px'}}}>
                <Grid
                  item xs={3} sx={{ backgroundColor: '#fff', border: '1px solid green', textAlign:'center', borderRadius:'10px 0 0 10px'}}>
                  <h5>{`${monthNames[new Date(dayBefore).getMonth()]}  ${new Date(dayBefore).getDate() + 1}`}</h5>
                  <small style={{color:'#9b0000', fontWeight:'bold'}}>{dayBeforeAppointment} {dayBeforeAppointment === 0 || dayBeforeAppointment === 1 ? 'Appt' : 'Appts'}</small>
                </Grid>
                <Grid item xs={3} style={{ backgroundColor: '#fff', border: '1px solid green', textAlign: 'center' }}>
                  <h5>{todaysDate ? "Today" : "" }</h5>
                  <small style={{color:'#9b0000', fontWeight:'bold'}}> {currentAppointment} {currentAppointment === 0 || currentAppointment === 1 ? 'Appt' : 'Appts'}</small>
                </Grid>
                <Grid item xs={3} style={{ backgroundColor: '#fff', border: '1px solid green', textAlign: 'center' }}>
                  <h5>{`${monthNames[new Date(dayAfter).getMonth()]}  ${new Date(dayAfter).getDate() + 1}`}</h5>
                  <small style={{color:'#9b0000', fontWeight:'bold'}}>{dayAfterAppointment} {dayAfterAppointment === 0 || dayAfterAppointment === 1 ? 'Appt' : 'Appts'}</small>
                </Grid>
                <Grid item xs={3} style={{ backgroundColor: '#fff', border: '1px solid green', textAlign: 'center', borderRadius:'0 10px 10px 0' }}>
                  <h5>{`${monthNames[new Date(twoDaysAfter).getMonth()]}  ${new Date(twoDaysAfter).getDate() + 1}`}</h5>
                  <small style={{color:'#9b0000', fontWeight:'bold'}}>{twoDaysAfterAppointment} {twoDaysAfterAppointment === 0 || twoDaysAfterAppointment === 1 ? 'Appt' : 'Appts'}</small>
                </Grid>
              </Grid>
            </div>

            <div>
              <Grid container style={{ justifyContent: 'space-between' }}>
                <Grid item xs={5} sm={2.5} sx={{ backgroundColor: '#fff', textAlign:'center', margin:'10px 0', borderRadius:'10px'}}>
                  <Typography variant='caption'>Heart Rate</Typography>
                  <div style={{width:'20%', height:'30%', margin:'0 auto'}}><img style={{width:'100%', height:'100%', borderRadius:'100%', }} src={heart} alt="" /></div>
                  <Typography variant='h6'>{state.medicalReport.heartRate === 'N/A' ? 'N/A' : `${state.medicalReport.heartRate} BPM`}</Typography>
                </Grid>
                <Grid item xs={5} sm={2.5} sx={{ backgroundColor: '#fff', textAlign:'center', margin:'10px 0', borderRadius:'10px' }}>
                  <Typography variant='caption'>Blood Pressure</Typography>
                  <div style={{width:'20%', height:'30%', margin:'0 auto'}}><img style={{width:'100%', height:'100%', borderRadius:'100%', }} src={BP} alt="" /></div>
                  <Typography variant='h6'>{state.medicalReport.bloodPressure === 'N/A' ? 'N/A' : `${state.medicalReport.bloodPressure} mmHg`}</Typography>
                </Grid>
                <Grid item xs={5} sm={2.5} sx={{ backgroundColor: '#fff', textAlign: 'center', margin:'10px 0', borderRadius:'10px' }}>
                  <Typography variant='caption'>Sugar Level</Typography>
                  <div style={{width:'20%', height:'30%', margin:'0 auto'}}><img style={{width:'100%', height:'100%', borderRadius:'100%', }} src={sugar} alt="" /></div>
                  <Typography variant='h6'>{state.medicalReport.sugarLevel === 'N/A' ? 'N/A' : `${state.medicalReport.sugarLevel}%`}</Typography>
                </Grid>
                <Grid item xs={5} sm={2.5} sx={{ backgroundColor: '#fff', textAlign: 'center', margin:'10px 0', borderRadius:'10px' }}>
                  <Typography variant='caption'>Lung Capacity</Typography>
                  <div style={{width:'20%', height:'30%', margin:'0 auto'}}><img style={{width:'100%', height:'100%', borderRadius:'100%', }} src={lungs} alt="" /></div>
                  <Typography variant='h6'>{state.medicalReport.lungCapacity === 'N/A' ? 'N/A' : `${state.medicalReport.lungCapacity} Ltrs`}</Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={5} sx={{ backgroundColor: '#FFF', borderRadius: '10px', padding:'20px', fontWeight:'bold' }}>
            <div style={{}}>Reminder <i style={{ color: 'orange' }} className="fa fa-bell" aria-hidden="true"></i></div>
            
            <div>Hey! you have {currentAppointment} <br /> {currentAppointment === 0 || currentAppointment === 1 ? 'appointment' : 'appointments'}  today</div>
            <StyledButton onClick={() => navigate('/view_appointment/todays')}>View Appointments</StyledButton>
            <div className={classes.ReminderImageDiv}><img className={classes.Image}  src={ReminderImage} alt="" /></div>
          </Grid>

        </Grid>

        <Grid container className={classes.section3}>
          
          <Grid item xs={12} sm={6.5} sx={{ backgroundColor: '#FFF', borderRadius: '10px', padding:'20px', margin:{xs:'0 0 10px', sm:'0'}, fontWeight:'bold'}}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Medical Reports</Typography>   
            {MedicalReportData.map((data, index) =>
              <div style={{ display: 'flex', justifyContent: 'space-between', }} key={index}>
                <div component={'span'} style={{ fontWeight: 'bold', width: '50%' }}>{data.name}</div>
                <hr style={{width:'10%', border: '1px solid #04514C' }}/>
                <div component={'span'} style={{ fontWeight: 'bold', width:'45%', textAlign:'right' }}>{data.result}</div>
              </div>
            )}
                
          </Grid>
              
          <Grid item xs={12} sm={5} sx={{ backgroundColor: '#FFF', borderRadius: '10px', padding:'20px', margin:{xs:'10px 0', sm:'0'}, fontWeight:'bold' }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', margin:'0 0 20px'}}>Appointment History</Typography>   
              {state.AppointmentData.length !== 0?state.AppointmentData.slice(0, 4).map((data) => 
                <Typography component='div' style={{ display: 'flex', justifyContent: 'space-between', }} key={data.id}>
                  <Typography component='div' style={{padding:'0 0 20px'}}>
                    <Typography style={{fontWeight:'bold'}}>{data.physician}</Typography>
                    <Typography variant='caption'>{data.timeslot.display}</Typography>
                  </Typography>
                  <Typography >{data.date}</Typography>
                </Typography>
                
            ) : "You do not have any appointment history"}
            {state.AppointmentData.length !== 0 ? <StyledButton onClick={()=>navigate('/view_appointment')} sx={{marginTop:'0',}}>View all <i style={{padding:'0 5px'}} className="fa fa-long-arrow-right"></i> </StyledButton> : ""}
          </Grid>
          
        </Grid>

     
      </div>
          
      
    
          
         
    </div>
  )
}

export default Dashboard