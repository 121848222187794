import * as React from 'react';
import {useNavigate} from 'react-router'
import {makeStyles} from '@material-ui/styles';




const useStyles = makeStyles({
  container: {
     backgroundColor: '#FFFFFF',
      display: 'flex',
      justifyContent: 'space-between',
      width: '90%',
      margin: '0 auto',
      border: '2px solid #04514C', borderRadius: '20px',
      minHeight: '120px',

	  ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      minHeight: '130px',
      border: '3px solid #04514C', borderRadius: '20px',
      margin: '0',
      },
  },

  par: {
    color: '#6E6D6D',
    fontSize: '16px',
    width: '50%',
    margin: '3% 4%',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '25px',
      width: '30%',
      margin: '1% 3%',
      },
    
  },

  button: {
    background: '#04514C',
    borderRadius: '10px',
    height: '30px',
    color: 'white',
    width: '40%',
    fontWeight: 'bold',
    fontSize: '10px',
    margin: '12% 3%',
    border: 'none',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      height: '50px',
      width: '30%',
      fontSize: '25px',
      margin: '3%'
      },
  }


})

export default function BookAppointmentCard({ image, name, link}) {

    const navigate = useNavigate();
    const classes = useStyles();
  return (

  //  Card
    <div>
      <div className={classes.container}>
        <p className={classes.par}>We can be your trusted healthcare provider at any day and time</p>
        <button className={classes.button} onClick={()=>navigate('/view_appointments')}>Book Appointment</button>
      </div>
    </div>
  );
}