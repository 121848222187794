import { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { makeStyles } from '@material-ui/styles';
import {TextField, Typography,  Grid, Button, MenuItem, Select, InputLabel} from '@mui/material';
import Navbar from '../Navbar';
import Footer from '../Footer';

import { useImmerReducer } from "use-immer";

// context
import StateContext from '../../context/StateContext';
import DispatchContext from '../../context/DispatchContext';

const useStyles = makeStyles({
  container: {
    margin: '20px auto 20px',
    width: '100%',
    backgroundColor: '##F5F5F5',
    display: 'block',
    padding:'0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '20px auto',
      padding:' 0',
      width: '70%',
      display: 'flex',
      justifyContent:'space-around',
      // height:'75%',
    },
  },

  imageDiv: {
    // display:'none',
    width: '90%',
    margin: '0 auto 20px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      // display: 'block',
      margin: '0',
      width: '40%',
      
    },
  },

  image: {
    width: '100%',
    height:'60vh',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '0',
      width: '100%',
      height:'90vh',
    },
  },

  form: {
    width: '90%',
    margin: '0 auto 20px',
    backgroundColor: '#FFFFFF',
    padding:'10px 10px 30px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            width: '45%', 
            padding:'10px 30px 30px',
            margin: '0',
        },
  },

  label: {
    fontSize: '20px',
    width: '100%',

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '20px',
    },
  },

  inputDiv: {
    margin:'20px auto 0',
    width: '90%',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      
    },
  },

  input: {
    width: '100%',
    // height: '40px',
    borderRadius: '10px',
    // paddingLeft: '10px',
    fontSize: '16px',
    border: '0.5px solid #04514C',
    // outline: '0.5px solid #04514C',
    margin: '5px 0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
      // height: '40px',
      fontSize: '20px',
      margin: '5px 0 10px',
    },
  },

  buttonDiv: {
    width: '90%',
    margin: '0 auto',
  },

  RegisterButton: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: 'white',
    margin: '20px 0',
    // fontWeight: 'bolder',
    backgroundColor: '#04514C',
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    border:'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: '#04514C',
    border:'0.5px solid #04514C',
    },

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: '25px',
    margin: '20px 0',
    width: '100%',
    height: '50px',
  },
  },

  LoginButton: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: '#04514C',
    margin: '20px 0',
    // fontWeight: 'bolder',
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    border:'0.5px solid #04514C',
  '&:hover': {
    backgroundColor: '#04514C',
    color:'#FFFFFF',
    },
  
  menuPaper: {
    maxHeight: 100
  },

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: '25px',
    margin: '20px 0',
    width: '100%',
    height: '50px',
  },
  },
  
})



// Regex To check the validity of email and insurance
const PHONE_REGEX = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
const POSTAL_REGEX = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/;


export default function UpdateProfile() {

  const GlobalState = useContext(StateContext)
  const GlobalDispatch = useContext(DispatchContext)

  let GlobalUser= JSON.parse(GlobalState.userInfo);

  const navigate = useNavigate();

  const classes = useStyles()



  const initialState = {
    phone: "",
    insurance: "",
    address: '',
    country: "",
    state: '',
    city: '',
    postalCode: '',
    sendRequest: 0,
    uploadedPicture: "",
    phoneError: {
      hasError: false,
      errorMessage : ""
    },
    insuranceError: {
      hasError: false,
      errorMessage : ""
    },
    addressError : {
      hasError : false,
      errorMessage : ''
    },
    countryError : {
      hasError : false,
      errorMessage : ''
    },
   stateError : {
      hasError : false,
      errorMessage : ''
    },
    cityError: {
      hasError: false,
      errorMessage: ''
   },
    postalCodeError: {
      hasError: false,
      errorMessage : ''
    },
    countries: [],
  }

  const reducerFunction = (draft, action) => {
    switch (action.type) {
      case "getUserDetails":
        draft.phone = action.userDetailsValue.phone
        draft.insurance = action.userDetailsValue.insurance
        draft.address = action.userDetailsValue.address
        draft.country = action.userDetailsValue.country
        draft.state = action.userDetailsValue.state
        draft.city = action.userDetailsValue.city
        draft.postalCode = action.userDetailsValue.postal_code
        draft.uploadedPicture = action.userDetailsValue.profile_picture
        break;
      case "changePhone":
        draft.phone = action.phoneValue
        draft.phoneError.hasError = false
        draft.phoneError.errorMessage = ""
        break;
      case "changeInsurance":
        draft.insurance = action.insuranceValue
        draft.insuranceError.hasError = false
        draft.insuranceError.errorMessage = ""
        break;
      case "changeCountry":
        draft.country = action.countryValue
        draft.countryError.hasError = false
        draft.countryError.errorMessage = ""
        break;
      case "changeAddress":
        draft.address = action.addressValue
         draft.addressError.hasError = false
        draft.addressError.errorMessage = ""
        break;
      case "changeState":
        draft.state = action.stateValue
        draft.stateError.hasError = false
        draft.stateError.errorMessage = ""
        break;
      case "changeCity":
        draft.city = action.cityValue
        draft.cityError.hasError = false
        draft.cityError.errorMessage = ""
        break;
      case "changePostalCode":
        draft.postalCode = action.postalCodeValue
        draft.postalCodeError.hasError = false
        draft.postalCodeError.errorMessage = ""   
        break;
      case "changeSendRequest":
        draft.sendRequest = draft.sendRequest + 1
        break;
     
      case "catchPhoneError":
        if (!PHONE_REGEX.test(action.phoneValue)) {
          draft.phoneError.hasError = true
          draft.phoneError.errorMessage = "Please enter a valid phone number"
        }
        break;
      case "catchInsuranceError":
        if (action.insuranceValue === null || action.insuranceValue === "") {
          draft.insuranceError.hasError = true
          draft.insuranceError.errorMessage = "Please enter a valid Insurance"
        }
        break;
      case "catchAddressError":
          if (action.addressValue === null || action.addressValue === "") {
            draft.addressError.hasError = true
            draft.addressError.errorMessage = "Please enter your address"
          }
          break;
      case "catchCountryError":
        if (action.countryValue === "") {
          draft.countryError.hasError = true
          draft.countryError.errorMessage = "Please select your country"
        }
        break;
      case "catchStateError":
        if (action.stateValue === null || action.stateValue === "") {
          draft.stateError.hasError = true
          draft.stateError.errorMessage = "Please select your state"
        }
        break;
      case "catchCityError":
        if (action.cityValue === null || action.cityValue === "") {
          draft.cityError.hasError = true
          draft.cityError.errorMessage = "Please select your city"
        }
        break;
      case "catchPostalCodeError":
        if (!POSTAL_REGEX.test(action.postalCodeValue)) {
          draft.postalCodeError.hasError = true
          draft.postalCodeError.errorMessage = "Enter a valid postal code"
        }
        break;
      case "getCountries":
        draft.countries = action.countriesValue
        break;
      case "catchUploadedPicture":
        draft.uploadedPicture = action.pictureValue
        break;
      default:
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(reducerFunction, initialState)
  

  // function that handles the form submission.
  const handleSubmit = async (e) => {
    e.preventDefault()

    if(!state.phoneError.hasError && !state.insuranceError.hasError ){
      dispatch({type: "changeSendRequest"})
    }
  } 

  // use effect to get all the user info
  useEffect(() => {
      
      const getProfile = async () => {

        try {
            const response = await axiosInstance.get(`/auth/profile/user/get_profile/?username=${GlobalUser.user}`, {
                headers: {
                   ' Content-Type': 'application/json',
                    Authorization: `Bearer ${GlobalState.userAccessToken}`,
                }
            })
            
            dispatch({type: "getUserDetails", userDetailsValue : response.data[0]})
        } catch (error) {
            console.log(error);
        }
      }
      
      getProfile()
    
  }, []);
  
  // Use effect that handles the form submission
  useEffect(() => {
    if (state.sendRequest){
      const UpdateProfile = async () => {
        const formData = new FormData();
        if (typeof state.uploadedPicture === 'string' || state.uploadedPicture === null) {
          formData.append('phone', state.phone)
          formData.append('insurance', state.insurance)
          formData.append('address', state.address)
          formData.append('country', state.country)
          formData.append('state', state.state)
          formData.append('city', state.city)
          formData.append('postal_code', state.postalCode)
          formData.append('patient', GlobalUser.user)
        } else {
          formData.append('phone', state.phone)
          formData.append('insurance', state.insurance)
          formData.append('address', state.address)
          formData.append('country', state.country)
          formData.append('state', state.state)
          formData.append('city', state.city)
          formData.append('postal_code', state.postalCode)
          formData.append('profile_picture', state.uploadedPicture)
          formData.append('patient', GlobalUser.user)
        }
        
        try {
          const response = await axiosInstance.patch(`auth/profile/user/update/${GlobalUser.user}/`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${GlobalState.userAccessToken}`
            }
          })
          navigate('/profile')
        } catch (error) {
          console.log(error)
        }
      }
      
      UpdateProfile()
  
    }
    
  }, [state.sendRequest]);


  useEffect(() => {
    const handleState = async() => {
    try {
      const response = await axiosInstance.get('https://restcountries.com/v3.1/all')
      const data = response.data
      const countries = data.map(dat => (dat.name.common));
      dispatch({type: 'getCountries', countriesValue: countries.sort()})
    } catch (error) {
      console.log(error);
    }
    }
    handleState()
    return () => {
      
    };
  }, []);


  return (
    <div>

      <div className={classes.container}>
     

        <form className={classes.form} onSubmit={handleSubmit}>
          {/* <h2 style={{textAlign:'center',}}>Profile</h2> */}
         

        <Grid item container style={{marginTop: '1rem'}} className={classes.inputDiv}>
            <TextField id="Phone Number" label="Phone Number" variant="outlined" name='Phone Number' fullWidth value={state.phone} onChange={(e)=>dispatch({type:"changePhone", phoneValue:e.target.value})} onBlur={(e)=>dispatch({type:"catchPhoneError", phoneValue: e.target.value})} error={state.phoneError.hasError ? true : false} helperText={state.phoneError.errorMessage}/>
        </Grid>
          
        <Grid item container style={{ marginTop: '1rem' }}>
            <TextField id="Insurance" label="Insurance" variant="outlined" name='Insurance' fullWidth value={state.insurance} onChange={(e) => dispatch({ type: "changeInsurance", insuranceValue: e.target.value })} onBlur={(e) => dispatch({ type: "catchInsuranceError", insuranceValue: e.target.value })} error={state.insuranceError.hasError ? true : false} helperText={state.insuranceError.errorMessage} />
        </Grid>
        
        <Grid item container style={{ marginTop: '1rem' }}>
          <TextField id="Address" label="Address" variant="outlined" name='Address' fullWidth value={state.address} onChange={(e)=>dispatch({type:"changeAddress",addressValue:e.target.value})} onBlur={(e)=> dispatch({type:"catchAddressError", addressValue:e.target.value})} error ={state.addressError.hasError ? true : false} helperText={state.addressError.errorMessage}/>
          </Grid>
          
        <Grid item container style={{ marginTop: '1rem' }}>
          <TextField
            id="outlined-select"
            select
            fullWidth
            label="Country"
            defaultValue=""
            value = {state.country}  
            onChange={(e)=>dispatch({type:"changeCountry", countryValue: e.target.value})}
            onBlur={(e) => dispatch({ type: "catchCountryError", countryValue: e.target.value })}
            error={state.countryError.hasError ? true : false}
            helperText={state.countryError.errorMessage}  
          >
            {state.countries.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        
        <Grid item container style={{ marginTop: '1rem' }}>
          <TextField id="State" label="State" variant="outlined" name='State' fullWidth value={state.state} onChange={(e)=>dispatch({type:"changeState", stateValue:e.target.value})}  onBlur={(e)=> dispatch({type:"catchStateError", stateValue:e.target.value})} error ={state.stateError.hasError ? true : false} helperText={state.stateError.errorMessage} />
        </Grid>
          
        <Grid item container style={{ marginTop: '1rem' }}>
          <TextField id="City" label="City" variant="outlined" name='City' fullWidth value={state.city} onChange={(e)=>dispatch({type:"changeCity", cityValue:e.target.value})}   onBlur={(e) => dispatch({ type: "catchCityError", cityValue: e.target.value })} error={state.cityError.hasError ? true : false} helperText={state.cityError.errorMessage}/>
        </Grid>
          
        <Grid item container style={{ marginTop: '1rem' }}>
            <TextField id="Postal Code" label="Postal Code" variant="outlined" name='Postal Code' fullWidth value={state.postalCode} onChange={(e) => dispatch({ type: "changePostalCode", postalCodeValue: e.target.value })} onBlur={(e) => dispatch({ type: "catchPostalCodeError", postalCodeValue: e.target.value })} error={state.postalCodeError.hasError ? true : false} helperText={state.postalCodeError.errorMessage} />
        </Grid>

          <Grid item container xs={12} lg={12} style={{ marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto' }} className={classes.picturesBtn}>
            {typeof state.uploadedPicture !== 'undefined' ? 
              <Typography variant="caption">{state.uploadedPicture.name}</Typography> : ""
            }
          <Button fullWidth component='label'>
          Upload Profile Picture
            <input type='file' accept="image/png, image/jpeg, image/gif" hidden onChange={(e)=>dispatch({type: 'catchUploadedPicture', pictureValue : e.target.files[0]})} />
            </Button>
            
         
        </Grid>  
        
        
          
        <div style={{display:'flex', gap:'20px'}}>
          <Grid item container xs = {12} style={{marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto'}} className={classes.RegisterButton}>
            <Button  fullWidth type='submit' >Update</Button>
          </Grid>
          
          <Grid item container xs={12} style={{ marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto' }} className={classes.RegisterButton}>
            <Button  fullWidth type='submit' onClick={()=>navigate('/profile')}>Cancel</Button>
          </Grid>
        </div>

      </form>

     

       

      </div>

    </div>  

   
  );
}