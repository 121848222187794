import { useNavigate } from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {Grid} from "@mui/material"
import logoFooter from './assets/logoFooter.png'



const useStyles = makeStyles({
    footer: {
        width: '100%',
        height: '50vh',
        backgroundColor: '#04514C',
        color: 'white',  
        paddingTop: '35px',
        
	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        height: '40vh',
	  },
    },

    headerImage: {
        width: '70px',
	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
       width: '100px'
	  },
    }

  

})


const Footer = () => {

    const classes = useStyles()
    const navigate = useNavigate();


  return (
    <div className={classes.footer}>
        
        <Grid container  justifyContent={{xs:'space-around', sm:'space-evenly'}} >
            <Grid item xs={4} lg={3}>
                <div><img className={classes.headerImage} src={logoFooter} alt={logoFooter} /></div>
                <p>Clinic Plus is the most trusted and efficient way of getting proper and fast healthcare any where you are in Africa.</p>
            </Grid>
            <Grid item xs={2} lg={3}>
                <div style={{marginBottom: '10px', fontWeight: 'bold', color:'black'}}>Products</div>
                <div onClick={()=>navigate('/')} style={{cursor: 'pointer'}}>Home</div>
                <div onClick={()=>navigate('/services')} style={{cursor: 'pointer'}}>Services</div>
                <div onClick={()=>navigate('/about')} style={{cursor: 'pointer'}}>About</div>
                <div onClick={()=>navigate('/contact')} style={{cursor: 'pointer'}}>Contact</div>
            </Grid>
            <Grid item xs={3} lg={3}>
                <div style={{marginBottom: '10px', fontWeight: 'bold', color:'black'}}>Services</div>
                <div onClick={()=>navigate('/appointments')} style={{cursor: 'pointer'}}>Appointment</div>
                <div onClick={()=>navigate('/newsletter')} style={{cursor: 'pointer'}}>Newletter</div>
                <div onClick={()=>navigate('/healthcare')} style={{cursor: 'pointer'}}>Healthcare</div>
            </Grid>
        </Grid>
    
        <div style={{textAlign: 'center', marginTop:'20px',}}>&copy; Clinic,{new Date().getFullYear()}</div>
    </div>
      
  )
}

export default Footer