import Footer from "./Footer"
import Navbar from "./Navbar"


const About = () => {
  return (

    <div>
      <Navbar />
      <div style={{height: '75vh'}}>
        
      </div>
      <Footer/>
    </div>
  )
}

export default About