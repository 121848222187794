import { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { makeStyles } from '@material-ui/styles';
import {TextField, IconButton} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Image from '../assets/registerPic.png'
import logo from '../assets/logo.png'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useImmerReducer } from "use-immer";
import { Alert } from '@mui/material';

const useStyles = makeStyles({
  container: {
    margin: '0 auto 20px',
    width: '100%',
    backgroundColor: '#fff',
    display: 'block',
    padding: '0',
    minHeight:'100vh',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '20px auto',
      padding:' 20px 0',
      width: '70%',
      display: 'flex',
      justifyContent:'space-around',
      // height:'75%',
    },
  },

  imageDiv: {
    display:'none',
    width: '90%',
    margin: '0 auto 20px',

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      display: 'block',
      margin: '0',
      width: '40%',
      
    },
  },

  image: {
    width: '100%',
    height:'60vh',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '0',
      width: '100%',
      height:'90vh',
    },
  },

  headerImage: {
    margin: '20px 0 20px',
    width: '70px',
	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    width: '100px',
    margin: '0 0 20px'
	  },
  },

  headerImageMobile: {
    margin: '20px 0 20px',
    width: '70px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      display: 'none'
	  },
  },

  form: {
    width: '90%',
    margin: '0 auto 50px',
    backgroundColor: '#FFFFFF',
    padding: '20px 10px 20px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '45%', 
        padding:'100px 30px 30px',
        margin: '30px 0',
    },
  },

  label: {
    fontSize: '20px',
    width: '100%',

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '20px',
    },
  },

  inputDiv: {
    margin:'20px auto 0',
    width: '90%',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      
    },
  },

  input: {
    width: '100%',
    // height: '40px',
    borderRadius: '10px',
    // paddingLeft: '10px',
    fontSize: '16px',
    border: '0.5px solid #04514C',
    // outline: '0.5px solid #04514C',
    margin: '5px 0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
      // height: '40px',
      fontSize: '20px',
      margin: '5px 0 10px',
    },
  },

  buttonDiv: {
    width: '90%',
    margin: '0 auto',
  },

  RegisterButton: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: 'white',
    margin: '20px 0',
    // fontWeight: 'bolder',
    backgroundColor: '#04514C',
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    border:'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: '#04514C',
    border:'0.5px solid #04514C',
    },

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: '25px',
    margin: '20px 0',
    width: '100%',
    height: '50px',
  },
  },

  LoginButton: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: '#04514C',
    margin: '20px 0',
    // fontWeight: 'bolder',
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    border:'0.5px solid #04514C',
  '&:hover': {
    backgroundColor: '#04514C',
    color:'#FFFFFF',
    },

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: '25px',
    margin: '20px 0',
    width: '100%',
    height: '50px',
  },
  },
  
})



// Regex To check the validity of email and password
const USERNAME_REGEX = /^[A-z][A-z0-9-_]{5,23}$/;
const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
// The string must be between 8 and 24 characters long
// The string must contain at least one lowercase letter (a-z)
// The string must contain at least one uppercase letter (A-Z)
// The string must contain at least one digit (0-9)
// The string must contain at least one of the following special characters: .!@#$%^&*
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#$%^&*]).{8,24}$/;


export default function Register() {


  const navigate = useNavigate();

  const classes = useStyles()

  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);

  const initialState = {
    email: '',
    password: '',
    username: '',
    firstName: '',
    lastName: '',
    sendRequest: 0,
    emailError : {
      hasError : false,
      errorMessage : ''
    },
    usernameError: {
      hasError: false,
      errorMessage : ""
    },
    passwordError: {
      hasError: false,
      errorMessage : ""
    },
    serverMessageUsername : '',
    serverMessageEmail : '',
    // serverMessageSimilarPassword: '',
    // serverMessageCommonPassword : '',
    // serverMessageNumericPassword : '',
  }

  const reducerFunction = (draft, action) => {
    switch(action.type){
      case "changeUsername":
        draft.username = action.usernameValue
        draft.usernameError.hasError = false
        draft.usernameError.errorMessage = ""
        draft.serverMessageUsername = ""
        break;
      case "changePassword":
        draft.password = action.passwordValue
        draft.passwordError.hasError = false
        draft.passwordError.errorMessage = ""
        break;
      case "changeEmail":
        draft.email = action.emailValue
         draft.emailError.hasError = false
        draft.emailError.errorMessage = ""
        draft.serverMessageEmail =""
        break;
      case "changeFirstName":
        draft.firstName = action.firstNameValue
        break;
      case "changeLastName":
        draft.lastName = action.lastNameValue
        break;
      case "changeSendRequest":
        draft.sendRequest = draft.sendRequest + 1
        break;
      case "catchEmailError": 
        if(!EMAIL_REGEX.test(action.emailValue)){
          draft.emailError.hasError = true
          draft.emailError.errorMessage = "Please enter a valid email"
        }
        break;
      case "catchUsernameError":
        if (!USERNAME_REGEX.test(action.usernameValue)) {
          draft.usernameError.hasError = true
          draft.usernameError.errorMessage = "Username must be atleast 6 characters"
        }
        break;
      case "catchPasswordError":
        if (!PASSWORD_REGEX.test(action.passwordValue)) {
          draft.passwordError.hasError = true
          draft.passwordError.errorMessage = "Password must be between 8 and 24 characters long, must contain at least one lowercase letter, one uppercase letter, one digit or one special characters: .!@#$%^&*"
        }
        break;
      case 'usernameExists':
        draft.serverMessageUsername = 'This username already exist'
        break;
      case 'emailExists':
        draft.serverMessageEmail = 'This email already exist'
        break;
      // case 'similarPassword':
      //   draft.serverMessageSimilarPassword = 'The password is too similar to the username!';
      //   break;
      // case 'commonPassword':
      //   draft.serverMessageCommonPassword = 'The password is too common!';
      //   break;
      // case 'numericPassword':
      //   draft.serverMessageNumericPassword = 'The password must not only contain numbers!';
      //   break;
      default:
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(reducerFunction, initialState)

  // function that handles the form submission.
  const handleSubmit = async (e) => {
    e.preventDefault()

    if(!state.usernameError.hasError && !state.emailError.hasError && !state.passwordError.hasError && state.firstName !== "" && state.lastName !=="" ){
      dispatch({type: "changeSendRequest"})
    }
  } 
  
  // Use effect that handles the form submission
  useEffect(() => {
    if (state.sendRequest) {
      const SignUp = async () => {
        try {
          const response = await axiosInstance.post('auth/signup/', {
            email: state.email,
            username: state.username,
            first_name: state.firstName,
            last_name: state.lastName,
            password: state.password
          })
          navigate('/login')
          
        } catch (error) {
          console.log(error);
          //
          // else
          if (error.response.data.email) {
						dispatch({ type: "emailExists" });
					} else if (error.response.data.non_field_errors) {
						dispatch({ type: "usernameExists" });
          }
          // else if (
					// 	error.response.data.password[0] ==="The password is too similar to the username."){
					// 	dispatch({ type: "similarPassword" });
					// } else if (
					// 	error.response.data.password[0] === "This password is too common."){
					// 	dispatch({ type: "commonPassword" });
					// }else if (
					// 	error.response.data.password[0] ==="This password is entirely numeric.") {
					// 	dispatch({ type: "numericPassword" });
					// }
        }
        
      }
      
      SignUp()
 
    }
    
  }, [state.sendRequest]);
    

  return (
    <div>
      {/* <Navbar/> */}
      
      <div className={classes.container}>
        <div style={{display: 'none'}}><Button onClick={()=>navigate('/')} color="inherit"><img className={classes.headerImageMobile} src={logo} alt="logo"/></Button></div>
        <div className={classes.imageDiv}>
          <Button onClick={()=>navigate('/')} color="inherit"><img className={classes.headerImage} src={logo} alt="logo"/></Button>
          <img className={classes.image} src={Image} alt="registerPic" />
        </div>

        <form className={classes.form} onSubmit={handleSubmit}>
          <h2 style={{textAlign:'center',}}>SIGN UP</h2>
          <p style={{ fontSize: '20px', textAlign: 'center', }}>Fill this form to register</p>

          
        {state.serverMessageUsername ? (<Alert severity = 'error'>{state.serverMessageUsername}</Alert>) : ('')}
        {state.serverMessageEmail ? (<Alert severity = 'error'>{state.serverMessageEmail}</Alert>) : ('')}
        {/* {state.serverMessageSimilarPassword ? (<Alert severity = 'error'>{state.serverMessageSimilarPassword}</Alert>) : ('')}
        {state.serverMessageCommonPassword ? (<Alert severity = 'error'>{state.serverMessageCommonPassword}</Alert>) : ('')}
        {state.serverMessageNumericPassword ? (<Alert severity='error'>{state.serverMessageNumericPassword}</Alert>) : ('')} */}
          


        <Grid item container style={{marginTop: '1rem'}} className={classes.inputDiv}>
            <TextField id="username" label="Username" variant="outlined" name='username' fullWidth value={state.username} onChange={(e)=>dispatch({type:"changeUsername", usernameValue:e.target.value})} onBlur={(e)=>dispatch({type:"catchUsernameError", usernameValue: e.target.value})} error={state.usernameError.hasError ? true : false} helperText={state.usernameError.errorMessage}/>
        </Grid>
        
        <Grid item container style={{ marginTop: '1rem' }}>
          <TextField id="email" label="Email" variant="outlined" name='email' fullWidth value={state.email} onChange={(e)=>dispatch({type:"changeEmail", emailValue:e.target.value})} onBlur={(e)=> dispatch({type:"catchEmailError", emailValue:e.target.value})} error ={state.emailError.hasError ? true : false} helperText={state.emailError.errorMessage}/>
        </Grid>
        
        <Grid item container style={{ marginTop: '1rem' }}>
          <TextField id="firstName" label="First Name" variant="outlined" name='firstName' fullWidth value={state.firstName} onChange={(e)=>dispatch({type:"changeFirstName", firstNameValue:e.target.value})}   />
        </Grid>
          
        <Grid item container style={{ marginTop: '1rem' }}>
          <TextField id="lastName" label="Last Name" variant="outlined" name='lastName' fullWidth value={state.lastName} onChange={(e)=>dispatch({type:"changeLastName", lastNameValue:e.target.value})}   />
        </Grid>
          
        <Grid item container style={{ marginTop: '1rem' }}>
            <TextField type = {showPassword ? "text" : "password" } id="password" label="Password" variant="outlined" name='password' fullWidth value={state.password} onChange={(e) => dispatch({ type: "changePassword", passwordValue: e.target.value })} onBlur={(e) => dispatch({ type: "catchPasswordError", passwordValue: e.target.value })} error={state.passwordError.hasError ? true : false} helperText={state.passwordError.errorMessage} /> <IconButton aria-label="toggle password visibility" onClick={handleTogglePassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
        </Grid>
          
        <Grid item container xs = {12} style={{marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto'}} className={classes.RegisterButton}>
          <Button  fullWidth type='submit' >SIGN UP</Button>
          </Grid>
          
        <Grid item container justifyContent='center' style={{marginTop: '1rem'}}>
          <Typography variant="small">Already have account?{" "} </Typography>
        </Grid>
        <Grid item container xs = {12} style={{marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto'}} className={classes.LoginButton}>
          <Button  fullWidth type='submit' onClick={()=>navigate('/login')}>SIGN IN</Button>
        </Grid>
          
      </form>

     

       

      </div>
      {/* <Footer/> */}
    </div>  

   
  );
}