import './components/Navbar.css'
import './components/player.css'
import './components/ScreenFlash.css'
import './components/user/profile.css'
import StateContext from './context/StateContext'
import DispatchContext from './context/DispatchContext';
import { useImmerReducer } from "use-immer";


import {useState, useEffect} from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import './App.css';
import Login from './components/user/Login'
import Register from './components/user/Register'
import Profile from './components/user/Profile'
import UpdateProfile from './components/user/UpdateProfile'
import Logout from './components/user/Logout'
import Homepage from './components/Homepage';
import About from './components/About';
import Services from './components/Services';
import Contact from './components/Contact';
import SignUp from './SignUp';
import Dashboard from './components/Dashboard/Dashboard';
import { Appointment } from './components/Dashboard/Appointment';
import BookAppointment from './components/Dashboard/BookAppointment';
import ViewAppointment from './components/Dashboard/ViewAppointment';
import SingleAppointment from './components/Dashboard/SingleAppointment';
import ViewTodaysAppointment from './components/Dashboard/ViewTodaysAppointment';
import Pharmacy from './components/Dashboard/Pharmacy';
import ScreenFlash from './ScreenFLash'


function App() {

  const [change, setChange] = useState(false);

  setTimeout(()=>setChange(true), 3000)

  const initialState = {
    // userUsername: localStorage.getItem('theUserUsername'),
    // userId: localStorage.getItem('theUserId'),
    // userEmail: localStorage.getItem("theUserEmail"),
    userInfo : (localStorage.getItem('theUser')),
    userAccessToken: localStorage.getItem('access_token'),
    userRefreshToken: localStorage.getItem('refresh_token'),
    userIsLogged: localStorage.getItem('theUser') ? true : false,
  }

  const reducerFunction = (draft, action) =>{
    switch(action.type){
      case "catchToken":
        draft.userAccessToken = action.accessTokenValue
        draft.userRefreshToken = action.refreshTokenValue
        break;
      case "userSignIn":
        draft.userInfo = JSON.stringify(action.userInfoValue)
        // draft.userUsername = action.usernameInfo
        // draft.userEmail = action.emailInfo
        // draft.userId = action.IdInfo
        draft.userIsLogged = true
        break;
      case "logout":
        draft.userIsLogged = false
        break;
      default:
        break;
      
    }
  }
  

  const [state, dispatch] = useImmerReducer(reducerFunction, initialState)

 
 

  useEffect(() => {
    if(state.userIsLogged){
      // localStorage.setItem('theUserUsername', state.userUsername);
      // localStorage.setItem('theUserEmail', state.userEmail);
      // localStorage.setItem('theUserId', state.userId);
      localStorage.setItem("theUser", state.userInfo);
      localStorage.setItem('access_token', state.userAccessToken);
      localStorage.setItem('refresh_token', state.userRefreshToken);
    } else {
      // localStorage.removeItem('theUserUsername')
      // localStorage.removeItem('theUserEmail')
      // localStorage.removeItem('theUserId')
      // localStorage.removeItem('theUser')
      // localStorage.removeItem('access_token')
      // localStorage.removeItem('refresh_token')
      localStorage.clear();
    }
  }, [state.userIsLogged])


  switch (change) {
    case false:
      return <ScreenFlash className="flash" />
      
    case true:
      return (
        <StateContext.Provider value={state}>
          <DispatchContext.Provider value={dispatch}>
            <HashRouter>
              {/* <Navbar /> */}
              <Routes>
                  
                <Route path="/" element=<Homepage /> />
                <Route path="/about" element=<About /> />
                <Route path="/services" element=<Services /> />
                <Route path="/contact" element=<Contact /> />
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/logout" element={<Logout />} />
                <Route path='/signup' element={<SignUp />} />
                <Route path='/update/profile/:username' element={state.userIsLogged ? <UpdateProfile /> : <Login />} />
                <Route path='/profile' element={state.userIsLogged ? <Profile /> : <Login />} />
                <Route path='/dashboard' element={state.userIsLogged ? <Dashboard /> : <Login />} />
                <Route path='/appointment' element={state.userIsLogged ? <Appointment /> : <Login />} />
                <Route path='/book_appointment' element={state.userIsLogged ? <BookAppointment /> : <Login />} />
                <Route path='/view_appointment' element={state.userIsLogged ? <ViewAppointment /> : <Login />} />
                <Route path='/single_appointment/:id' element={state.userIsLogged ? <SingleAppointment /> : <Login />} />
                <Route path='/view_appointment/todays' element={state.userIsLogged ? <ViewTodaysAppointment /> : <Login/>} />
                <Route path='/get_pharmacy_nearby' element={state.userIsLogged ? <Pharmacy /> : <Login/>} />
              </Routes>
              {/* <Footer/> */}
            </HashRouter>
          </DispatchContext.Provider>
        </StateContext.Provider>
          
      )
    
    default:
      break;
  }
}

export default App;
