import { useState, useEffect, useContext} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { makeStyles } from '@material-ui/styles';
import { Grid,Card,CardActions,CardContent,Button,Typography,Divider, List, ListItem,ListItemAvatar, ListItemText, AppBar, Toolbar, Dialog, DialogContent, DialogContentText, TextField, DialogActions } from '@mui/material';
import Navbar from '../Navbar';
import Footer from '../Footer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import logo from '../assets/logo.png'


import { useImmerReducer } from "use-immer";

// context
import StateContext from '../../context/StateContext';
import DispatchContext from '../../context/DispatchContext';
import UpdateAppoinment from './UpdateAppointment';

const useStyles = makeStyles({
  container: {
    margin: '30% auto 20px',
    width: '100%',
    backgroundColor: '##F5F5F5',
    display: 'block',
    padding: '0',
    zIndex:'-1000',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        margin: '10% auto',
        padding:' 0',
        width: '70%',
        display: 'flex',
        justifyContent: 'space-around',
      // height:'75%',
    },
  },

   header: {
    backgroundColor: '#ffffff',
    border: '1px solid #2F845B',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    height: '70px',
    color: '#000000',  
    display: 'flex',
    justifyContent: 'space-between',
    margin: '',	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    justifyContent:'space-around',
	  },
  },
  
  headerImage: {
    width: '60px',
	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    width: '100px'
	  },
    },


  imageDiv: {
    // display:'none',
    width: '90%',
    margin: '0 auto 20px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      // display: 'block',
      margin: '0',
      width: '40%',
      
    },
  },

  image: {
    width: '100%',
    height:'60vh',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '0',
      width: '100%',
      height:'90vh',
    },
  },

  form: {
    width: '90%',
    margin: '0 auto 20px',
    backgroundColor: '#FFFFFF',
    padding: '10px 10px 30px',
    // zIndex:'1',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '45%', 
        padding:'10px 30px 30px',
        margin: '0',
        // zIndex:'1',
    },
  },

  label: {
    fontSize: '20px',
    width: '100%',

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '20px',
    },
  },

  inputDiv: {
    margin:'20px auto 0',
    width: '90%',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      
    },
  },

  input: {
    width: '100%',
    // height: '40px',
    borderRadius: '10px',
    // paddingLeft: '10px',
    fontSize: '16px',
    border: '0.5px solid #04514C',
    // outline: '0.5px solid #04514C',
    margin: '5px 0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
      // height: '40px',
      fontSize: '20px',
      margin: '5px 0 10px',
    },
  },

  buttonDiv: {
    width: '90%',
    margin: '0 auto',
  },

  RegisterButton: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: 'white',
    margin: '20px 0',
    // fontWeight: 'bolder',
    backgroundColor: '#04514C',
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    border:'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: '#04514C',
    border:'0.5px solid #04514C',
    },

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: '25px',
    margin: '20px 0',
    width: '100%',
    height: '50px',
  },
  },

    LoginButton: {
        fontSize: '20px',
        fontFamily: 'Poppins',
        color: '#04514C',
        margin: '20px 0',
        // fontWeight: 'bolder',
        backgroundColor: '#FFFFFF',
        width: '100%',
        height: '40px',
        borderRadius: '5px',
        border: '0.5px solid #04514C',
        '&:hover': {
            backgroundColor: '#04514C',
            color: '#FFFFFF',
        },
    },

    datepicker: {
        width: '100%',
        height: '50px',
        border: '0.1px solid gray',
        borderRadius: '5px',
        zIndex: '1000',
        padding:'0 10px',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key

        },
    },

})



// Regex To check the validity of email and insurance
const PHONE_REGEX = /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
const POSTAL_REGEX = /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/;


export default function SingleAppoinment() {
  


  const GlobalState = useContext(StateContext)
  const GlobalDispatch = useContext(DispatchContext)

  let GlobalUser= JSON.parse(GlobalState.userInfo);

  const navigate = useNavigate();
  const params = useParams();

    const classes = useStyles()
    
    // const [startDate, setStartDate] = useState(new Date());


  const initialState = {
    singleAppointment: '',
    sendRequest: 0,

  }

  const reducerFunction = (draft, action) => {
    switch (action.type) {
      case 'catchSingleAppointment':
        draft.singleAppointment = action.singleAppointmentData
        break;
      // case "changePhysician":
      //   draft.physician = action.physicianValue
      //   // draft.phoneError.hasError = false
      //   // draft.phoneError.errorMessage = ""
      //   break;
      // case "changeTimeslot":
      //   draft.timeslot = action.timeslotValue
      //   // draft.insuranceError.hasError = false
      //   // draft.insuranceError.errorMessage = ""
      //   break;
      // case "changePurpose":
      //   draft.purpose = action.purposeValue
      //   //  draft.countryError.hasError = false
      //   // draft.countryError.errorMessage = ""
      //   break;
      // case "catchDate":
      //   draft.date = (action.dateValue)
      //   break;
      // case "changeSendRequest":
      //   draft.sendRequest = draft.sendRequest + 1
      //   break;
      default:
        break;
    }
  }


  const [state, dispatch] = useImmerReducer(reducerFunction, initialState)
  

  // function that handles the form submission.
  const handleSubmit = async (e) => {
    e.preventDefault()

    // if(!state.phoneError.hasError && !state.insuranceError.hasError ){
      dispatch({type: "changeSendRequest"})
    // }
  } 

  useEffect(() => {
    
      const GetSingleAppointment = async () => {  
        try {
          const response = await axiosInstance.get(`appointments/${params.id}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${GlobalState.userAccessToken}`
            }
          })
          dispatch({type: 'catchSingleAppointment', singleAppointmentData: response.data})
        } catch (error) {
          console.log(error)
        }
      }
      
      GetSingleAppointment()
 
 
    
  }, []);


  async function deleteHandler(){
    const confirmDelete = window.confirm('Are you sure you want to delete this appointment?');
    if(confirmDelete){
        try{
          const response = await axiosInstance.delete(`appointments/${params.id}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${GlobalState.userAccessToken}`
            }
          })
          navigate('/view_appointment')
            // dispatch({type: 'openTheSnack'})
            // dispatch({type: 'disabledTheButton'})
        }catch(e){
            // dispatch({type : 'allowTheButton'})
        } 
    }
    }



   const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

  
  return (

      <Grid container sx={{ width: { xs: '90%', sm: '80%' }, margin: '80px auto 20px',}}>
     
          <AppBar component='nav'>
          <Toolbar className={classes.header}>
            <Button onClick={() => navigate('/')} color="inherit"><img className={classes.headerImage} src={logo} alt="logo" /></Button>
            <Typography onClick={() => navigate('/dashboard')} item xs={6} sm={12} sx={{fontWeight:'bold', textAlign:'center', margin: '0', fontSize:{xs:'1rem', sm:'1.5rem'}, cursor:'pointer'}}>
            Dashboard
          </Typography>
          <Typography onClick={() => navigate('/appointment')} item xs={6} sm={12} sx={{fontWeight:'bold', textAlign:'center', margin: '0', fontSize:{xs:'1rem', sm:'1.5rem'}, cursor:'pointer'}}>
            Appointment
          </Typography>
          </Toolbar>
        </AppBar>

        <Grid item xs={12} sx={{fontWeight:'bold', textAlign:'center', margin: '5% 0 10px', fontSize:{xs:'1.5rem', sm:'2rem'},}} >
        <List
          sx={{
            width: '100%',
            maxWidth: 800,
            bgcolor: 'background.paper',
            margin: '10px auto',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          <ListItem >
            <ListItemText style={{ display: 'flex', justifyContent: 'space-between' }} primary="Physician" secondary={state.singleAppointment.physician} />
          </ListItem>
          <Divider component="li" />
          
          <ListItem>
            <ListItemText style={{display:'flex', justifyContent:'space-between'}} primary="Date" secondary={state.singleAppointment.date} />
          </ListItem>
          <Divider component="li" />
                
          <ListItem>
            <ListItemText style={{display:'flex', justifyContent:'space-between'}} primary="Time" secondary={state.singleAppointment !== ""?state.singleAppointment.timeslot.display : ""} />
          </ListItem>
          <Divider component="li" />
                
          <ListItem>
            <ListItemText primary="Purpose" secondary={state.singleAppointment.purpose} />
            </ListItem>
            
          <ListItem sx={{display:'flex', justifyContent:'space-between'}}>
              {/* <ListItemText style={{display:'flex', justifyContent:'space-between'}} primary="Update" secondary="Delete" /> */}
              <Button onClick={handleClickOpen} sx={{ color: '#04514C', ':hover': { color: '' }, fontWeight:'bold' }}>Update</Button>
              <Button  sx={{ color: '#A71841 ', ':hover': {}, fontWeight: 'bold' }} onClick={deleteHandler}>Delete</Button>
             
              <Dialog open={open} onClose={handleClose} fullScreen>
                <UpdateAppoinment
                  appointmentData={state.singleAppointment}
                  closeDialog={handleClose} />
              </Dialog>
          </ListItem>
      </List>
      </Grid>


     

       

      </Grid>
   
  );
}