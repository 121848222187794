import Ellipse_1 from "./Ellipse_1.png"


export const DoctorsData = [
    {
        id: 1,
        name : 'Dr. Kelly',
        speciality: 'Optical Surgeon',
        link : '/about',
        image : Ellipse_1
    },
    {
        id: 2,
        name : 'Dr. Andrew',
        speciality: 'Optical Surgeon',
        link : '/about',
        image : Ellipse_1
    },
    {
        id:3,
        name : 'Dr. Charles',
        speciality: 'Optical Surgeon',
        link : '/about',
        image : Ellipse_1
    },
    {
        id:4,
        name : 'Dr. Mark Pude',
        speciality: 'Optical Surgeon',
        link : '/about',
        image : Ellipse_1
    },
    // {
    //     name : 'Dr. Mark Pude',
    //     link : '/about',
    //     image : Ellipse_1
    // },
    // {
    //     name : 'Dr. Mark Pude',
    //     link : '/about',
    //     image : Ellipse_1
    // },

]

