import React from 'react'
import { Link, useNavigate} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import { Grid,Card,CardActions,CardContent,Button,Typography, Divider, List, ListItem,ListItemAvatar, ListItemText, AppBar, Toolbar, Dialog, DialogContent, DialogContentText, TextField, DialogActions, CardActionArea, CardMedia } from '@mui/material';
import Sidebar from './Sidebar';
import { styled } from '@mui/material/styles';
import Walgreen from '../assets/pharmacy/walgreen.png'
import CVS from '../assets/pharmacy/cvs.png'
import Walmart from '../assets/pharmacy/Walmart.png'
import Rite from '../assets/pharmacy/Rite.png'
import Kroger from '../assets/pharmacy/Kroger.png'
import Albertsons from '../assets/pharmacy/Albertsons.png'
import Publix from '../assets/pharmacy/Publix.png'
import AmerisourceBergen from '../assets/pharmacy/AmerisourceBergen.png'
import McKesson from '../assets/pharmacy/McKesson.png'
import Costco from '../assets/pharmacy/Costco.png'
import Cardinal from '../assets/pharmacy/Cardinal.png'
import Ahold from '../assets/pharmacy/Ahold.png'



const useStyles = makeStyles({
    container: {
        margin: '0 auto',
        width: '100%',
        display: 'block',
        padding: '0',
        minHeight: '50vh',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            margin: '20px auto',
            padding: '0',
            width: '80%',
            minHeight: '50vh',
            display: 'flex',
        },
    },

    bgImage: {
        backgroundImage: 'url("https://media.istockphoto.com/id/1189304032/photo/doctor-holding-digital-tablet-at-meeting-room.jpg?s=612x612&w=0&k=20&c=RtQn8w_vhzGYbflSa1B5ea9Ji70O8wHpSgGBSh0anUg=")',
        backgroundColor: '#04514C',
        height: '100vh',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        position: 'relative',
        opacity: '0.8',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      
        },
    },

    rightDiv: {
        width: '100%',
        backgroundColor:'#95A5A6',
        minHeight: '100vh',
        padding:'20px',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            minHeight: '100vh',
        },
    },

    CardDiv: {
        width: '30%',
        // margin: '20px',
        backgroundColor: '#FFF',
        minHeight: '30vh',
        borderRadius: '10px',
        padding: '10px',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            // minHeight: '100vh',
        },
    }
,
    imageDiv: {
        width: '100%',
        // backgroundColor: '#FFF',
        // minHeight: '30vh',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            // minHeight: '100vh',
        },
    },

    image: {
        width: '50%',
        margin: '10px 0 20px',
        // backgroundColor: '#FFF',
        // minHeight: '30vh',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            // minHeight: '100vh',
        },
    }

})  


export default function Pharmacy() {
    const classes = useStyles()
    const navigate = useNavigate();

    const listOfPharmacy = [
        {
            id: 1,
            logo: Walgreen,
            about: "Walgreens, is an American company that operates the second-largest pharmacy store chain in the United States behind CVS Health. It specializes in filling prescriptions, health and wellness products, health information, and photo services.It was founded in Chicago, Illinois, in 1901, and is headquartered in the Chicago suburb of Deerfield, Illinois.",
            website: 'http://walgreens.com/'
        },
        {
            id: 2,
            logo: CVS,
            about: "CVS Health Corporation (previously CVS Corporation and CVS Caremark Corporation) is an American healthcare company that owns CVS Pharmacy, a retail pharmacy chain; CVS Caremark, a pharmacy benefits manager; and Aetna, a health insurance provider, among many other brands. The company's headquarters is in Woonsocket, Rhode Island.",
            website: 'http://cvshealth.com/'
        },
        {
            id: 3,
            logo: Walmart,
            about: "Walmart Inc. is an American multinational retail corporation that operates a chain of hypermarkets (also called supercenters), discount department stores, and grocery stores from the United States, headquartered in Bentonville, Arkansas.[10] The company was founded by Sam Walton and James 'Bud' Walton in nearby Rogers, Arkansas in 1962 and incorporated under Delaware General Corporation Law on October 31, 1969. It also owns and operates Sam's Club retail warehouses.",
            website: 'https://www.walmart.com/'
        },
        {
            id: 4,
            logo: Rite,
            about: "Rite Aid Corporation is an American drugstore chain based in Philadelphia, Pennsylvania.[8] It was founded in 1962 in Scranton, Pennsylvania, by Alex Grass under the name Thrift D Discount Center. The company ranked No. 148 in the Fortune 500 list of the largest United States corporations by total revenue.",
            website: 'https://www.riteaid.com/'
        },
        {
            id: 5,
            logo: Kroger,
            about: "Kroger, is an American retail company that operates (either directly or through its subsidiaries) supermarkets and multi-department stores throughout the United States.Founded by Bernard Kroger in 1883 in Cincinnati, Ohio, Kroger operates 2,720 grocery retail stores under its various banners and divisions in 35 states and the District of Columbia[6] with store formats that include 134 multi-department stores, 2,277 combo stores, 188 marketplace stores, and 121 price-impact warehouse stores.",
            website: 'https://www.kroger.com/'
        },
        {
            id: 6,
            logo: Albertsons,
            about: "Albertsons Companies, Inc. is an American grocery company founded and headquartered in Boise, Idaho. With 2,253 stores as of the third quarter of fiscal year 2020 and 270,000 employees as of fiscal year 2019, the company is the second-largest supermarket chain in North America after Kroger. Albertsons ranked 53rd in the 2018 Fortune 500 list of the largest United States corporations by total revenue.",
            website: 'https://www.albertsons.com/'
        },
        {
            id: 7,
            logo: Publix,
            about: "Publix, is an employee-owned American supermarket chain headquartered in Lakeland, Florida.[1] Founded in 1930 by George W. Jenkins, Publix is a private corporation that is wholly owned by present and past employees and members of the Jenkins family.[5] Publix operates throughout the Southeastern United States, with locations in Florida (836), Georgia (197), Alabama (83), South Carolina (66), Tennessee (53), North Carolina (51), and Virginia (19).",
            website: 'https://www.publix.com/'
        },
        {
            id: 8,
            logo: AmerisourceBergen,
            about: "AmerisourceBergen Corporation is an American drug wholesale company that was formed by the merger of Bergen Brunswig and AmeriSource in 2001. They provide drug distribution and consulting related to medical business operations and patient services. They also distribute a line of brand name and generic pharmaceuticals, over-the-counter (OTC) health care products and home health care supplies and equipment to health care providers throughout the United States, including acute care hospitals and health systems, independent and chain retail pharmacies, mail-order facilities, physicians, clinics and other alternate site facilities, as well as nursing and assisted living centers.",
            website: 'https://www.amerisourcebergen.com/'
        },
        {
            id: 9,
            logo: McKesson,
            about: "McKesson Corporation is an American company distributing pharmaceuticals and providing health information technology, medical supplies, and care management tools. The company delivers a third of all pharmaceuticals used in North America and employs over 78,000 employees.[2] McKesson had revenues of $238.2 billion in its fiscal year ending March 31, 2021. McKesson is based in Irving, Texas, and distributes health care systems, medical supplies and pharmaceutical products. Additionally, McKesson provides extensive network infrastructure for the health care industry.",
            website: 'http://mckesson.com/'
        },
        {
            id: 10,
            logo: Costco,
            about: "Costco Wholesale and also known simply as Costco) is an American multinational corporation which operates a chain of membership-only big-box retail stores (warehouse club). As of 2022, Costco is the fifth largest retailer in the world and is the world's largest retailer of choice and prime beef, organic foods, rotisserie chicken, and wine as of 2016. In 2021, Costco was ranked #10 on the Fortune 500 rankings of the largest United States corporations by total revenue.",
            website: 'https://www.costco.com/'
        },
        {
            id: 11,
            logo: Cardinal,
            about: "Cardinal Health, Inc. is an American multinational health care services company, and the 14th highest revenue generating company in the United States. Its headquarters are in Dublin, Ohio and Dublin, Ireland (EMEA). The company specializes in the distribution of pharmaceuticals and medical products, serving more than 100,000 locations.[4] The company also manufactures medical and surgical products, including gloves, surgical apparel, and fluid management products. In addition, it operates one of the largest networks of radiopharmacies in the U.S.[5] Cardinal Health provides medical products to over 75 percent of hospitals in the United States.",
            website: 'https://www.cardinalhealth.com/'
        },
        {
            id: 12,
            logo: Ahold,
            about: "Ahold Delhaize,[3] is a Dutch multinational retail and wholesaling company. Its name comes from a merger between Ahold (Dutch) and Delhaize Group (Belgian), the two predecessor companies that merged to form the present-day Ahold Delhaize. Its business format includes supermarkets, convenience stores, hypermarkets, online grocery, online non-food, drugstores, and liquor stores. Its 21 local brands employ 375,000 people at 6,500 stores in 11 countries, predominantly the Netherlands and Belgium.",
            website: 'http://www.aholddelhaize.com/'
        },

         
    ]
  return (
    <div className={classes.container}>
      <div style={{minWidth: '20%', marginBottom:'50px'}}>
        <Sidebar  />
      </div>

      
      <div className={classes.rightDiv}>
        {/* <div className={classes.bgImage}></div> */}
              <Grid container>
        {/* <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            margin: '10px',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          <ListItem >
            <ListItemText style={{ display: 'flex', justifyContent: 'space-between' }} primary="Physician" secondary='physician' />
          </ListItem>
          <Divider component="li" />
          
          <ListItem>
            <ListItemText style={{display:'flex', justifyContent:'space-between'}} primary="Date" secondary='date' />
          </ListItem>
          <Divider component="li" />
                
          <ListItem>
            <ListItemText style={{display:'flex', justifyContent:'space-between'}} primary="Time" secondary='time' />
          </ListItem>
          <Divider component="li" />
                
          <ListItem>
            <ListItemText primary="Purpose" secondary='purpose'  />
            </ListItem>
            
          
      </List> */}
       {listOfPharmacy.map((data, index) => (        
        <Grid item xs={12} sm={5.8} className={classes.CardDiv} component='div' key={index} sx={{margin: '10px auto', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',}}>
            
                <Typography component='div' className={classes.imageDiv}><img className={classes.image} src={data.logo} alt="" /></Typography>      
               <Typography sx={{ textAlign: 'left', marginBottom:'10px' }}>{data.about}</Typography>           
               <Typography><a href={data.website} style={{textDecoration: 'none'}}>Click to explore pharmacy. </a> </Typography>
            
        </Grid>
                  
        ))}
                  
      </Grid>
      </div>
          
      
    
          
         
    </div>
  )
}
