
export const AppointmentData = [
    {
        body : 'Select the type of care you need assistance with easily from our care categories.',
        title : 'Select your type of care',
        image : <svg width="20" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M34.1667 0H15.8333V15.8333H0V34.1667H15.8333V50H34.1667V34.1667H50V15.8333H34.1667V0ZM46.6667 19.1667V30.8333H30.8333V46.6667H19.1667V30.8333H3.33333V19.1667H19.1667V3.33333H30.8333V19.1667H46.6667Z" fill="white"/>
                </svg>

    },
    {
        body : ' Search for available medical practisioners that are willing to assist you.',
        title : 'Find available Practisioner',
        image : <svg width="20" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M39.8798 37.1248L26.8949 24.1399C28.9099 21.5349 29.9999 18.3499 29.9999 14.9999C29.9999 10.99 28.4349 7.22997 25.6049 4.39498C22.7749 1.55999 19.0049 0 14.9999 0C10.995 0 7.22497 1.56499 4.39498 4.39498C1.55999 7.22497 0 10.99 0 14.9999C0 19.0049 1.56499 22.7749 4.39498 25.6049C7.22497 28.4399 10.99 29.9999 14.9999 29.9999C18.3499 29.9999 21.5299 28.9099 24.1349 26.8999L37.1198 39.8798C37.1579 39.9179 37.2031 39.9481 37.2529 39.9688C37.3026 39.9894 37.356 40 37.4098 40C37.4637 40 37.517 39.9894 37.5668 39.9688C37.6166 39.9481 37.6618 39.9179 37.6998 39.8798L39.8798 37.7048C39.9179 37.6668 39.9481 37.6216 39.9688 37.5718C39.9894 37.522 40 37.4687 40 37.4148C40 37.361 39.9894 37.3076 39.9688 37.2579C39.9481 37.2081 39.9179 37.1629 39.8798 37.1248ZM22.9199 22.9199C20.7999 25.0349 17.9899 26.1999 14.9999 26.1999C12.0099 26.1999 9.19996 25.0349 7.07997 22.9199C4.96498 20.7999 3.79998 17.9899 3.79998 14.9999C3.79998 12.0099 4.96498 9.19496 7.07997 7.07997C9.19996 4.96498 12.0099 3.79998 14.9999 3.79998C17.9899 3.79998 20.8049 4.95998 22.9199 7.07997C25.0349 9.19996 26.1999 12.0099 26.1999 14.9999C26.1999 17.9899 25.0349 20.8049 22.9199 22.9199Z" fill="white"/>
                </svg>

    },
    {
        body : 'Fix a date and time for your check-up and you are all set.',
        title: 'Schedule a meeting',
        image : <svg width="95" height="95" viewBox="0 0 95 95" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="47.5" cy="47.5" r="47.5" fill="#04514C"/>
                    <path d="M48 69.875C43.6735 69.875 39.4442 68.5921 35.8469 66.1884C32.2496 63.7848 29.4458 60.3683 27.7901 56.3712C26.1345 52.3741 25.7013 47.9757 26.5453 43.7324C27.3894 39.4891 29.4728 35.5913 32.532 32.532C35.5913 29.4728 39.4891 27.3894 43.7324 26.5453C47.9757 25.7013 52.3741 26.1345 56.3712 27.7901C60.3683 29.4458 63.7848 32.2496 66.1884 35.8469C68.5921 39.4442 69.875 43.6735 69.875 48C69.875 53.8016 67.5703 59.3656 63.468 63.468C59.3656 67.5703 53.8016 69.875 48 69.875ZM48 29.25C44.2916 29.25 40.6665 30.3497 37.5831 32.41C34.4996 34.4702 32.0964 37.3986 30.6773 40.8247C29.2581 44.2508 28.8868 48.0208 29.6103 51.658C30.3338 55.2951 32.1195 58.636 34.7418 61.2583C37.364 63.8805 40.7049 65.6663 44.3421 66.3897C47.9792 67.1132 51.7492 66.7419 55.1753 65.3228C58.6014 63.9036 61.5298 61.5004 63.5901 58.417C65.6503 55.3335 66.75 51.7084 66.75 48C66.75 43.0272 64.7746 38.2581 61.2583 34.7418C57.742 31.2255 52.9728 29.25 48 29.25Z" fill="white"/>
                    <path d="M55.1719 57.375L46.4375 48.6406V33.9375H49.5625V47.3438L57.375 55.1719L55.1719 57.375Z" fill="white"/>
                </svg>

    },
]

