import {useEffect, useContext, useState} from 'react';
import { Link, useNavigate } from "react-router-dom"
import axiosInstance from '../../api/axios';
import { makeStyles } from '@material-ui/styles';
import {TextField, IconButton} from '@mui/material';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Image from '../assets/registerPic.png'
import { useImmerReducer } from "use-immer";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import logo from '../assets/logo.png'


// contexts
import DispatchContext from '../../context/DispatchContext';
import { Alert } from '@mui/material';



const useStyles = makeStyles({
  container: {
    margin: '0 auto 20px',
    width: '100%',
    backgroundColor: '#fff',
    display: 'block',
    padding:'0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '20px auto',
      padding:' 20px 0',
      width: '70%',
      display: 'flex',
      justifyContent:'space-around',
    },
  },


  imageDiv: {
    display:'none',
    width: '90%',
    margin: '0 auto 20px',

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      display: 'block',
      margin: '0',
      width: '40%',
      
    },
  },

  image: {
    width: '100%',
    height:'60vh',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '0',
      width: '100%',
      height:'90vh',
    },
  },

  headerImage: {
    margin: '20px 0 20px',
    width: '70px',
	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    width: '100px',
    margin: '0 0 20px'
	  },
  },

  headerImageMobile: {
    margin: '20px 0 20px',
    width: '70px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      display: 'none'
	  },
  },

  form: {
    width: '90%',
    margin: '0 auto 20px',
    backgroundColor: '#FFFFFF',
    padding: '50px 10px 50px',
    boxShadow: '0px 1px 4px rgba(0, 0, 0, 0.25)',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '45%', 
        padding:'100px 30px 30px',
        margin: '30px 0',
    },
  },

  label: {
    fontSize: '20px',
    width: '100%',

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '20px',
    },
  },

  inputDiv: {
    margin:'20px auto 0',
    width: '90%',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      
    },
  },

  input: {
    width: '100%',
    height: '40px',
    borderRadius: '10px',
    fontSize: '16px',
    border: '0.5px solid #04514C',
    outline: '0.5px solid #04514C',
    margin: '5px 0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
      height: '40px',
      fontSize: '20px',
      margin: '5px 0 10px',
    },
  },

  buttonDiv: {
    width: '90%',
    margin: '0 auto',
  },

  LoginButton: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: 'white',
    margin: '20px 0',
    backgroundColor: '#04514C',
    width: '100%',
    height: '40px',
    borderRadius: '10px',
    border:'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: '#04514C',
    border:'0.5px solid #04514C',
    },

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: '25px',
    margin: '20px 0',
    width: '100%',
    height: '50px',
  },
  },

  RegisterButton: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: '#04514C',
    margin: '20px 0',
    backgroundColor: '#FFFFFF',
    width: '100%',
    height: '40px',
    borderRadius: '10px',
    border:'0.5px solid #04514C',
  '&:hover': {
    backgroundColor: '#04514C',
    color:'#FFFFFF',
    },

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: '25px',
    margin: '20px 0',
    width: '100%',
    height: '50px',
  },
  },
  
})



// Regex To check the validity of email and password
const USERNAME_REGEX = /^[A-z][A-z0-9-_]{5,23}$/;
// The string must be between 8 and 24 characters long
// The string must contain at least one lowercase letter (a-z)
// The string must contain at least one uppercase letter (A-Z)
// The string must contain at least one digit (0-9)
// The string must contain at least one of the following special characters: .!@#$%^&*
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#$%^&*]).{8,24}$/;




export default function Login() {

  const classes = useStyles()
  const navigate = useNavigate();
  const GlobalDispatch = useContext(DispatchContext)

  const [showPassword, setShowPassword] = useState(false)

  const handleTogglePassword = () => setShowPassword(showPassword => !showPassword);
  
  // Initial state for the login data
  const initialState = {
    username : "",
    password : "",
    sendRequest : "",
    accessToken : "",
    refreshToken: "",
    usernameError: {
      hasError: false,
      errorMessage: ""
    }, 
    passwordError: {
      hasError: false,
      errorMessage: ""
    },
    disabledBtn: false,
    serverError: false,
    
  }


  // function to handle each cases.
  const reducerFunction = (draft, action) => {
    switch(action.type){
      case "changeUsername":
        draft.username = action.usernameValue
        draft.usernameError.hasError = false
        draft.usernameError.errorMessage = ""
        draft.serverError = false
        break;
      case "changePassword":
        draft.password = action.passwordValue
        draft.passwordError.hasError = false
        draft.passwordError.errorMessage = ""
        draft.serverError = false
        break;
      case "changeSendRequest": 
        draft.sendRequest = draft.sendRequest + 1
        break;
      case "catchToken":
        draft.accessToken = action.accessTokenValue
        draft.refreshToken = action.refreshToken
        break;
      case "catchUsernameError":
        if (!USERNAME_REGEX.test(action.usernameValue)) {
          draft.usernameError.hasError = true
          draft.usernameError.errorMessage = "Username must be atleast 6 characters"
        }
        break;
      case "catchPasswordError":
        if (!PASSWORD_REGEX.test(action.passwordValue)) {
          draft.passwordError.hasError = true
          draft.passwordError.errorMessage = "Password must be between 8 and 24 characters long, must contain at least one lowercase letter, one uppercase letter, one digit or one special characters: .!@#$%^&*"
        }
        break;
      case 'disabledTheButton':
        draft.disabledBtn = true
        break;
      case 'allowTheButton':
        draft.disabledBtn = false
        break;
      case 'catchServerError' :
        draft.serverError = true
        break;
      default:
        break;
    }
  }

  
  // declaring the state and dispatch variable
  const [state, dispatch] = useImmerReducer(reducerFunction, initialState)


  // function yo handle login button
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!state.usernameError.hasError && !state.passwordError.hasError) {
      dispatch({ type: "changeSendRequest" })
    }
    
  }

  // use effect function to hanlde the login post request
  useEffect(() => {
    if(state.sendRequest){
      const SignIn = async() => {
        try{
          const response = await axiosInstance.post("auth/api/create/", {
            username: state.username,
            password: state.password
          })
          dispatch({
            type : "catchToken", accessTokenValue : response.data.access, refreshTokenValue : response.data.refresh
          })
          GlobalDispatch({
            type : "catchToken", accessTokenValue : response.data.access, refreshTokenValue : response.data.refresh
          })
          
          }catch(error){
          dispatch({type: 'catchServerError'})
        }
      }

      SignIn()
    }
  }, [state.sendRequest])


  // use effect function to get the user login details
  useEffect(() => {
    if(state.accessToken !== ""){
      const getUserInfo = async() => {
        try{
          const response = await axiosInstance.get("auth/login/", {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
              Authorization: `Bearer ${state.accessToken}` 
            }
          })
          navigate('/dashboard')
          GlobalDispatch({ type: "userSignIn", userInfoValue: response.data })
          dispatch({type: 'disabledTheButton'})
     
        }catch(error){
          console.log(error)
        }
      }

      getUserInfo()
    }
  }, [state.accessToken])

  return (

    <div>
      <div className={classes.container}>
        <div style={{display: 'none'}}><Button onClick={()=>navigate('/')} color="inherit"><img className={classes.headerImageMobile} src={logo} alt="logo"/></Button></div>
        <div className={classes.imageDiv}>
          <Button onClick={()=>navigate('/')} color="inherit"><img className={classes.headerImage} src={logo} alt="logo"/></Button>
          <img className={classes.image} src={Image} alt="registerPic" />
        </div>

        <form className={classes.form} onSubmit={handleSubmit}>
          <h2 style={{textAlign:'center',}}>LOGIN</h2>
          <p style={{fontSize: '20px', textAlign:'center',}}>Dear User, Welcome Back</p>

          {state.serverError ? <Alert severity="error">Incorrect Username or Passowrd</Alert> : ''}

          <Grid item container style={{marginTop: '1rem'}} className={classes.inputDiv}>
            <TextField id="username" label="Username" variant="outlined" name='username' value={state.username} onChange={(e)=>dispatch({type:"changeUsername", usernameValue:e.target.value})} onBlur={(e)=>dispatch({type:"catchUsernameError", usernameValue:e.target.value})} error={state.usernameError.hasError || state.serverError? true : false} helperText={state.usernameError.errorMessage} fullWidth />
          </Grid>
            
          <Grid item container style={{ marginTop: '1rem'}}>
              <TextField type = {showPassword ? "text" : "password" } id="password" label="Password" variant="outlined" name='password' value={state.password} onChange={(e) => dispatch({type:"changePassword", passwordValue : e.target.value})} onBlur={(e) => dispatch({ type: "catchPasswordError", passwordValue: e.target.value })} error={state.passwordError.hasError || state.serverError ? true : false} helperText={state.passwordError.errorMessage} fullWidth /> <IconButton aria-label="toggle password visibility" onClick={handleTogglePassword}>{showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
          </Grid>
            
          <Grid item container justifyContent='left' style={{marginTop: '1rem'}}>
            <Link to='/register' variant="small" >Forget password?{" "} </Link>
          </Grid>
            
          <Grid item container xs = {12} style={{marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto'}} className={classes.LoginButton}>
            <Button  fullWidth type='submit' disabled = {state.disabledBtn}>SIGN IN</Button>
          </Grid>
            
          <Grid item container justifyContent='left' style={{marginTop: '1rem'}}>
            <Typography variant="small">Don't have an account?{" "} </Typography>
          </Grid>

          <Grid item container xs = {12} style={{marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto'}} className={classes.RegisterButton}>
            <Button  fullWidth type='submit' onClick={()=>navigate('/register')}>SIGN UP</Button>
          </Grid>

        </form>

      </div>
    </div>
      

   
  );
}