import React from 'react'
import {makeStyles} from '@material-ui/styles';


const useStyles = makeStyles({
    circle1:{
        position: 'absolute',
        width: '70%',
        height: '70%',
        left: '22%',
        top: '72%',

        background: '#04514C',
        border: '5px solid #04514C',
        borderRadius: '100%',
        
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            margin: '20px auto',
            width: '40%',
            height: '80%',
            left: '50%',
            top: '70%',
        },
    },

    circle2:{
        position: 'absolute',
        width: '70%',
        height: '70%',
        left: '26%',
        top: '75%',

        background: '#FFFFFF',
        border: '5px solid #04514C',
        borderRadius: '100%',
        
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
            margin: '20px auto 20px',
            width: '40%',
            height: '80%',
            left: '55%',
            top: '75%',
        },
    }
  
})


const TopCircle = () => {

    const classes = useStyles()
  return (
    <div>
        <div className={classes.circle1}></div>
        <div className={classes.circle2}></div>
    </div>
  )
}

export default TopCircle