import { makeStyles } from '@material-ui/styles';
import Navbar from './Navbar';
import Footer from './Footer';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';


const useStyles = makeStyles({
  bgImage: {
    backgroundImage: 'url("https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKr0Kj1XrEzBJQk-6RuK8pTrltgS3w3MXDNQ&usqp=CAU")',
    backgroundColor: '#04514C',
    height: '500px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      height: '75vh',
    },
  },

  card: {
    maxWidth: 300,
    position: 'absolute',
    top: '20%',
    left: '10%',
    borderRadius: '10px',
     ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      maxWidth: 600,
      
    },
  },
})




const Services = () => {

  const classes = useStyles()
  return (
    <div>
      <Navbar/>
      <div className={classes.bgImage}>
        
      </div>
       
    <Card className={classes.card }>
     
      <CardContent>
        <div style={{display: 'flex', justifyContent: 'space-around'}}>
          <Typography gutterBottom variant="body2" component="div">
            Good Evening!
          </Typography>
          <Link to='/register' style={{fontSize: '16px',}}>view clinic plans</Link>
        </div>
          
        <Typography sx={{fontSize:{xs:'1.8em', sm:'3.5rem',},margin:{xs:'5px 0', sm:'20px 0'} }}>
          Clinic Medicare Plans
        </Typography>
         
        <Typography variant="body2" color="text.secondary" sx={{fontSize:{xs:'16px', sm:'20px', },margin:{xs:'5px 0', sm:'20px 0'} }}>
          We help make it easy to find Medicare coverage that’s right for you, with guidance from start to finish, flexible coverage options, and more.
        </Typography>
      </CardContent>
    
    </Card>
      
      <Footer/>
    </div>
  )
}

export default Services