import {useEffect, useContext } from 'react';
import StateContext from '../../context/StateContext';
import axiosInstance from '../../api/axios'
import { Grid,Button,Typography,Divider, List, ListItem, ListItemText, AppBar, Toolbar } from '@mui/material';
import { useNavigate,} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import logo from '../assets/logo.png'
import { useImmerReducer } from 'use-immer';



const useStyles = makeStyles({
   header: {
    backgroundColor: '#ffffff',
    border: '1px solid #2F845B',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    height: '70px',
    color: '#000000',  
    display: 'flex',
    justifyContent: 'space-between',
    margin: '',	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    justifyContent:'space-around',
	  },
  },
  
  headerImage: {
    width: '60px',
	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    width: '100px'
	  },
    }
})


export default function ViewTodaysAppointment() {

  const classes = useStyles()
  const navigate = useNavigate();

  const GlobalState = useContext(StateContext);


  const GlobalUser = JSON.parse(GlobalState.userInfo);


  const initialState = {
    appointmentData: [],
    sendRequest: '',
    loading: true,
  }


  const reducerFunction = (draft, action) => {
    switch (action.type) {
      case 'catchAppointmentData':
        draft.appointmentData = action.appointmentInfo
        break;
      case 'catchSendRequest':
        draft.sendRequest = draft.sendRequest + 1
        break;
      case 'catchLoading':
        draft.loading = action.loadingValue
        break;
      default:
        break;
    }
  }


  useEffect(() => {
    const GetAppointment = async () => {
      
      try {
        const response = await axiosInstance.get(`/appointments/appointments_for/?username=${GlobalUser.user}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${GlobalState.userAccessToken}`
          }
        })
        dispatch({ type: 'catchAppointmentData', appointmentInfo: response.data })
        dispatch({type:'catchLoading', loadingValue: false})
      } catch(error) {
        console.log(error);
      }
    }
    
    GetAppointment()
  }, []);


  async function deleteHandler(id){
    const confirmDelete = window.confirm('Are you sure you want to delete this appointment?');
    if(confirmDelete){
        try{
          const response = await axiosInstance.delete(`appointments/${id}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${GlobalState.userAccessToken}`
            }
          })
          navigate(0)
            // dispatch({type: 'openTheSnack'})
            // dispatch({type: 'disabledTheButton'})
        }catch(e){
            // dispatch({type : 'allowTheButton'})
        } 
    }
    }


 const todaysDate = `${new Date().getFullYear()}-${String(new Date().getMonth() + 1).padStart(2, '0')}-${String(new Date().getDate()).padStart(2, '0')}`
  
  const [state, dispatch] = useImmerReducer(reducerFunction, initialState)
  
    const datas = state.appointmentData
    
    const todaysAppointment = state.appointmentData.filter(data => (data.date === todaysDate))

  return (
    
    <Grid container sx={{ width: { xs: '90%', sm: '80%' }, margin: '80px auto 20px', }}>
    
      <AppBar component='nav'>
        <Toolbar className={classes.header}>
            <Button onClick={() => navigate('/')} color="inherit"><img className={classes.headerImage} src={logo} alt="logo" /></Button>
          <Typography onClick={() => navigate('/dashboard')} item xs={6} sm={12} sx={{fontWeight:'bold', textAlign:'center', margin: '0', fontSize:{xs:'1rem', sm:'1.5rem'}, cursor:'pointer'}}>
            Dashboard
          </Typography>
          <Typography onClick={() => navigate('/appointment')} item xs={6} sm={12} sx={{fontWeight:'bold', textAlign:'center', margin: '0', fontSize:{xs:'1rem', sm:'1.5rem'}, cursor:'pointer'}}>
            Appointment
          </Typography>
        </Toolbar>
      </AppBar>

      {state.loading ? <div className="mx-auto my-5">
        <div className="spinner-border text-success my-5 mx-5 p-4" role="status">
        <span className="sr-only">Loading...</span>
        </div>
      </div> : 

      
      todaysAppointment === null || todaysAppointment === 'undefined' || todaysAppointment === "" || todaysAppointment.length === 0 ? <Typography sx={{textAlign:'center', margin:'10px auto'}}>
        You currently don't have any appointment at the moment. To book an appointment <Button onClick={()=>navigate('/book_appointment')}>Click here</Button>
      </Typography>:
        
        todaysAppointment.map((data, index) =>
       
      <Grid item xs={12} sm={4} sx={{fontWeight:'bold', textAlign:'center', margin: '0 0 10px', fontSize:{xs:'1.5rem', sm:'2rem'},}} key={index}>
        <List
          sx={{
            width: '100%',
            maxWidth: 360,
            bgcolor: 'background.paper',
            margin: '10px auto',
            boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          }}
        >
          <ListItem >
            <ListItemText style={{ display: 'flex', justifyContent: 'space-between' }} primary="Physician" secondary={data.physician} />
          </ListItem>
          <Divider component="li" />
          
          <ListItem>
            <ListItemText style={{display:'flex', justifyContent:'space-between'}} primary="Date" secondary={data.date} />
          </ListItem>
          <Divider component="li" />
                
          <ListItem>
            <ListItemText style={{display:'flex', justifyContent:'space-between'}} primary="Time" secondary={data.timeslot.display} />
          </ListItem>
          <Divider component="li" />
                
          <ListItem>
            <ListItemText primary="Purpose" secondary={data.purpose.length > 30 ? data.purpose.slice(0, 30)+'...': data.purpose}  />
            </ListItem>
            
          <ListItem sx={{display:'flex', justifyContent:'space-between'}}>
              {/* <ListItemText style={{display:'flex', justifyContent:'space-between'}} primary="Update" secondary="Delete" /> */}
              <Button onClick={()=>navigate(`/single_appointment/${data.id}`)} sx={{ color: '#04514C', ':hover': { color: '' }, fontWeight:'bold' }}>View | Update</Button>
              <Button onClick={()=>deleteHandler(data.id)} sx={{ color: '#A71841 ', ':hover': {}, fontWeight: 'bold' }}>Delete</Button>
             
              {/* <Dialog open={open} onClose={handleClose} fullScreen>
                <UpdateAppoinment
                  appointmentData={state.appointmentData}
                  closeDialog={handleClose} />
              </Dialog> */}
          </ListItem>
      </List>
      </Grid>

          )}
      
      {todaysAppointment.length === 0 ? "" : <Grid onClick={()=>navigate('/book_appointment')} item xs={12} sm={4} sx={{fontWeight:'bold', textAlign:'center', margin: '10% 0 10px', fontSize:{xs:'1.5rem', sm:'3rem'}, cursor:'pointer'}}>
          <i className="fa fa-plus-circle" aria-hidden="true"></i>
      </Grid>}
      
    </Grid>
    
  );
}

