import {useContext} from 'react'
import { useNavigate } from 'react-router-dom';
import {makeStyles} from '@material-ui/styles';
import {  Button, Grid, Typography, } from "@mui/material"
import Section_1_Pic from './assets/Section_1_pic.png'
import Ellipse1 from './assets/Ellipse_1.png'
import Ellipse2 from './assets/Ellipse_2.png'
import { styled } from '@mui/material/styles';
import WhatWeDoCard from './cards/WhatWeDo';
import AppointmentCard from './cards/Appointment';
import DoctorsCard from './cards/Doctors';
import TestimonialCard from './cards/Testimonial';
import BookAppointmentCard from './cards/BookAppointment';
import { Data } from './assets/what_we_do/data'
import { AppointmentData } from './assets/Appointment/data'
import { DoctorsData } from './assets/Doctors/DoctorsData'
import {TestimonialData} from './cards/Testimonial';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Zoom } from 'react-awesome-reveal';
import { Player, } from 'video-react';
import TopCircle from './assets/Background_Design/TopCircle';
import MiddleCircle from './assets/Background_Design/MiddleCircle';
import LeftCircle from './assets/Background_Design/LeftCircle';
import Navbar from './Navbar';
import Footer from './Footer';
import StateContext from '../context/StateContext';
import Poster from "./assets/poster.png"
import Pills from "./assets/Pills.mp4"



const useStyles = makeStyles({
  container: {
      margin: '20px auto 20px',
      width: '90%',
      backgroundColor: '#F5F5F5',
      paddingTop:'100px',
	  ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        margin: '20px auto 20px',
        width: '75%',
      },
  },

  Section_1: {
    width: '300px',
    fontSize: '35px',
    fontWeight: '600',

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key

      width: '693px',
      fontSize: '60px',
      },
  },

  Section_1_Div: {
    display: 'none',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      display: 'block',
      width: '50%',
      height: '404px',
    },
  },

  Section_1_Logo: {
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '280px',
      height: '300px',
      position: 'absolute',
      top: '120px',
      left: '70%',
    },
   
  },

   Section_1_Image: {
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '320px',
      height: '250px',
      position: 'absolute',
      top: '90px',
      left: '67%',
    },
  },


  subSection_1: {
    color: '#989393',
    fontWeight: '400',
    fontSize: '19px',
    lineHeigh: '28.5px',
    width: '300px',

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '320px',
      },
  },

  whatWeDo: {
    lineHeight: '30px',
    color: '#000000',
    heigth: '27px',
    textAlign: 'center',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      textAlign: 'left',
    },
  },

  CardLayout: {
    display: 'block',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      display: 'flex',
      justifyContent: 'space-between'
      
    },
  },

  CardDiv: {
    width: '100%',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '80%',

    },
  }

})

const StyledButton = styled(Button)(() => ({
    fontFamily: 'Poppins',
    color: 'white',
    marginTop: '20px',
    fontWeight: 'bolder',
    backgroundColor: '#04514C',
    width: '100%',
    height: '47px',

  ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    width: '230px',
    height: '80px',
  },
}));

const Homepage = () => {
  const navigate = useNavigate();
  const classes = useStyles()

  return (
    <div> 
      <Navbar/>
    <div className={classes.container}>
      
      <TopCircle />
      <MiddleCircle />
      <LeftCircle />
      {/* <DownCircle/> */}
      
      {/* Div for the first section */}
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <div style={{width: ''}}>
          <div className={classes.Section_1}>Affordable <span style={{color:'#04514C'}}>Healthcare</span> anytime, <span style={{color:'#04514C'}}>anywhere</span></div>
          <Typography className={classes.subSection_1}>Connect with Affordable healthcare professionals around you and get the best treatment.</Typography>
          <StyledButton onClick={()=>navigate('/about')} variant="contained" color='success'><Typography>Learn More</Typography></StyledButton>
        </div>

        <div className={classes.Section_1_Div}>
          <div>
            <img className={classes.Section_1_Logo} src={Ellipse1} alt="ellipse_1" />
            <img className={classes.Section_1_Logo} src={Ellipse2} alt="ellipse_2" />
            <img className={classes.Section_1_Image} src={Section_1_Pic} alt="Section_1__pic" />
          </div>
        </div>
      </div>

      
      {/* What we do card */}

        <Typography className={classes.whatWeDo} style={{margin: '20px auto', fontWeight:'bold',fontSize: '20px',}}>What we do</Typography>
      
      <Grid container spacing={2} className={classes.CardLayout} >
        {Data.map((data, index) => 
          <Grid item xs={12} md={3} key={index}>
            <Zoom>
              <WhatWeDoCard image={data.image} body={data.body} link={data.link} />
            </Zoom>
          </Grid>
        )}   
      </Grid>
   
      
      {/* How to book appointment card */}
      <div className={classes.CardDiv}>
        <Typography className={classes.whatWeDo} style={{ margin: '30px auto', fontWeight: 'bold', fontSize: '20px',}}>How To Book An Appointment</Typography>

        <Grid container spacing={2} className={classes.CardLayout}>
           
          {AppointmentData.map((data, index) =>
          <Grid item xs={12} md={4} key={index}>
            <Zoom>
              <AppointmentCard image={data.image} body={data.body} title={data.title}  />
              </Zoom>
          </Grid>
          )}
        </Grid>
      </div>
      

      
      {/* TOp doctors card */}

    <div className={classes.CardDiv}>
      <Typography className={classes.whatWeDo} style={{ margin: '30px auto 10px', fontWeight: 'bold', fontSize: '20px', }}>Out Top Doctors</Typography>

      
      <Grid container className={classes.CardLayout} >
        {DoctorsData.map((data, index) =>
          <Grid item xs={12} md={6} key={index}>
            <Zoom>
              <DoctorsCard image={data.image} name={data.name}/>
            </Zoom>
          </Grid>
        )}
      </Grid>
      </div>
      

    <div className={classes.CardDiv}>

      {/* Patient Testimonial card */}
      <Typography className={classes.whatWeDo} style={{ margin: '30px auto 10px', fontWeight: 'bold', fontSize: '20px', }}>Patients' Testimonials</Typography>

    
      <Grid container className={classes.CardLayout} spacing={4}>
        {TestimonialData.map((data, index) => 
          <Grid item xs={12} md={6} key={index}>
            <Zoom>
              <TestimonialCard content={data.content} name={data.name}  />
            </Zoom>
          </Grid>
        )}   
      </Grid>
    </div>

    <div className={classes.CardDiv}>

      {/* short video */}
        <Typography className={classes.whatWeDo} sx={{ margin: '30px auto 10px', fontWeight: 'bold', fontSize: '20px', }}>Why use Clinic Plus?</Typography>
        <Typography sx={{color:'#6E6D6D', fontSize:{xs:'16px', sm:'20px'}, width:'95%', margin:{xs:'auto', sm:'0'}}}>Click on the video below to find out more.</Typography>

    
        <Grid container className={classes.CardLayout} sx={{
          width: {xs:'95%', sm:'90%'}, margin:{xs:'20px auto', sm:'20px 0'}, borderRadius:'10px'}}>
      
          <Grid item xs={12} md={12}>
            <Zoom>
                <Player
                playsInline
                poster={Poster}
                src={Pills}
              />
            </Zoom>
          </Grid>
   
        </Grid> 
    </div>
        
    <Zoom>
        <BookAppointmentCard />
    </Zoom>

  
      </div>
    <Footer/>
</div>
    
  )
}

export default Homepage