import {useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { makeStyles } from '@material-ui/styles';
import Button from '@mui/material/Button';
import logo from '../assets/logo.png'


// context
import StateContext from '../../context/StateContext';

import { useImmerReducer } from "use-immer";

const useStyles = makeStyles({
  container: {
    margin: '20px auto 20px',
    // width: '80%',
    backgroundColor: '#F5F5F5',
    display: 'block',
    // padding: '10px 20px',
    border: '5px solid black',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '0 auto',
    //   padding:' 10px 0',
    //   width: '60%',
    //   display: 'flex',
    //   justifyContent:'space-around',
      // height:'75%',
    },
    
  },

  headerImage: {
    margin: '20px 0 20px',
    width: '70px',
	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    width: '100px',
    margin: '0 0 20px'
	  },
  },

  
  
})


export default function Profile() {

    const GlobalState = useContext(StateContext)
  const navigate = useNavigate();

    const classes = useStyles()
    let GlobalUser= JSON.parse(GlobalState.userInfo);

    const initialState = {
        userDetails: {
            fName: "",
            lName :"",
            phoneNumber: "",
            address: "",
            insurance: "",
            country: "",
            state: "",
            city: "",
            postalCode: "",
            profilePicture: ""
        }
      
  }

  const reducerFunction = (draft, action) => {
    switch(action.type){
        case "getUserDetails":
            draft.userDetails.phoneNumber = action.userDetailsValue.phone
            draft.userDetails.address = action.userDetailsValue.address
            draft.userDetails.address = action.userDetailsValue.address
            draft.userDetails.country = action.userDetailsValue.country
            draft.userDetails.state = action.userDetailsValue.state
            draft.userDetails.city = action.userDetailsValue.city
            draft.userDetails.postalCode = action.userDetailsValue.postal_code
            draft.userDetails.insurance = action.userDetailsValue.insurance
            draft.userDetails.profilePicture = action.userDetailsValue.profile_picture
            break;
       
      default:
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(reducerFunction, initialState)


  // Use effect that handles the form submission
    useEffect(() => {
      
      const getProfile = async () => {

        try {
            const response = await axiosInstance.get(`/auth/profile/user/get_profile/?username=${GlobalUser.user}`, {
                headers: {
                   ' Content-Type': 'application/json',
                    Authorization: `Bearer ${GlobalState.userAccessToken}`,
                }
            })
            
            dispatch({type: "getUserDetails", userDetailsValue : response.data[0]})
        } catch (error) {
            console.log(error);
        }
      }
      
      getProfile()
    
  }, []);

    

  return (
      <div className="vh-100 " >
        <div className="container pb-3 h-100">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div className="col col-lg-8 mb-lg-0">
            <Button onClick={()=>navigate('/')} color="inherit"><img className={classes.headerImage} src={logo} alt="logo"/></Button>
              <div className="card mb-3" style={{borderRadius: ".5rem"}}>
                <div className="row g-0">
                  <div className="col-md-3 gradient-custom text-center text-white"
                    style={{borderTopLeftRadius: ".5rem", borderBottomLeftRadius: ".5rem"}}>
                    <img src={state.userDetails.profilePicture} alt="Avatar" className="img-fluid my-5" style={{width: "150px",height:'150px', borderRadius:'100%'}} />
                        <h5>{GlobalUser.fName} {GlobalUser.lName}</h5>
                        <i className="fa fa-edit mb-5" onClick={()=>navigate(`/update/profile/${GlobalUser.user}`)}></i>
                  </div>
                  <div className="col-md-8">
                    <div className="card-body p-4">
                        <div style={{display: 'flex',justifyContent: 'space-between'}}>
                            <h6>Information</h6>
                            <i onClick={()=>navigate('/dashboard')} className="fa fa-arrow-circle-left" style={{fontSize:'20px', cursor:'pointer'}}></i>
                        </div>
                      <hr className="mt-0 mb-4"/>
                      <div className="row pt-1">
                        <div className="col-12 col-md-6 mb-3">
                          <h6>Email</h6>
                            <p className="text-muted">{GlobalUser.email }</p>
                        </div>
                        <div className="col-12 col-md-6  mb-3">
                            <h6>Phone</h6>
                            <p className="text-muted">{state.userDetails.phoneNumber}</p>
                        </div>
                        <div className="col-12 col-md-6  mb-3">
                          <h6>Insurance</h6>
                            <p className="text-muted">{state.userDetails.insurance}</p>
                        </div>
                        <div className="col-12 col-md-6  mb-3">
                            <h6>Address</h6>
                            <p className="text-muted">{state.userDetails.address }</p>
                        </div>
                        <div className="col-12 col-md-6  mb-3">
                            <h6>Country</h6>
                            <p className="text-muted">{state.userDetails.country }</p>
                        </div>
                        <div className="col-12 col-md-6  mb-3">
                            <h6>State</h6>
                            <p className="text-muted">{state.userDetails.state}</p>
                        </div>
                        <div className="col-12 col-md-6  mb-3">
                            <h6>City</h6>
                            <p className="text-muted">{state.userDetails.city}</p>
                        </div>
                        <div className="col-12 col-md-6  mb-3">
                            <h6>Postal Code</h6>
                            <p className="text-muted">{state.userDetails.postalCode }</p>
                        </div>
                        
                    </div>
                      
                        
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

   
  );
}