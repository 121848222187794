import { useEffect, useContext } from "react";
import StateContext from "../../context/StateContext"
import DispatchContext from "../../context/DispatchContext"


// const events = [
//   "load",
//   "mousemove",
//   "mousedown",
//   "click",
//   "scroll",
//   "keypress",
// ];

const Logout = () => {
//   const GlobalDispatch = useContext(DispatchContext)
//   let timer;

// useEffect(() => {
//   Object.values(events).forEach((item) => {
//     window.addEventListener(item, () => {
//       resetTimer();
//       handleTimer();
//     });
//   });
// }, []);

// const resetTimer = () => {
//   if (timer) clearTimeout(timer);
// };

// const handleTimer = () => {
//   timer = setTimeout(() => {
//     resetTimer();
//     Object.values(events).forEach((item) => {
//       window.removeEventListener(item, resetTimer);
//     });
//     logoutAction();
//   }, 10000);
// };

// const logoutAction = () => {
//   // localStorage.clear();
//   GlobalDispatch({ type: "logout" })
//   window.location.pathname = "/login";
// };
//   return children;
  
 
};

export default Logout;