import React from 'react'
import { useNavigate} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Button from '@mui/material/Button';
import Sidebar from './Sidebar';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';




const useStyles = makeStyles({
  container: {
    margin: '0 auto',
    width: '100%',
    display: 'block',
    padding: '0',
    minHeight: '50vh',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        margin: '20px auto',
        padding: '0',
        width: '80%',
        minHeight: '50vh',
        display: 'flex',
    },
  },

  bgImage: {
    backgroundImage: 'url("https://media.istockphoto.com/id/1189304032/photo/doctor-holding-digital-tablet-at-meeting-room.jpg?s=612x612&w=0&k=20&c=RtQn8w_vhzGYbflSa1B5ea9Ji70O8wHpSgGBSh0anUg=")',
    backgroundColor: '#04514C',
    height: '100vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    opacity:'0.8',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      
    },
  },

  rightDiv: {
    width: '100%',
    backgroundColor: '#AFE1AF',
    minHeight: '100vh',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        minHeight: '50vh',
    },
  },

  section1: {
    display:'flex',
    width: '100%',
    justifyContent: 'space-around',
    padding: '20px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
    },
  },

  section2: {
    display:'block',
    minHeight:'30vh',
    padding: '20px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      justifyContent: 'space-between',
      minHeight:'20vh',
    },
  },

  section3: {
    // display:'block',
    minHeight:'30vh',
    padding: '20px',
    margin: ' 0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      justifyContent: 'space-between',
      minHeight:'20vh',
    },
  },

  TextDiv: {
    position: 'absolute',
    top: '30%',
    left: '10%',
    width: '60%',
  
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      position: 'absolute',
      top: '25%',
      left: '30%',
      width:'30%',
    },
  },

  Image: {
    width: '100%',
    height: '25vh',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      height: '240px',
      width:'200px',
    },
  },
  
})

const StyledButton = styled(Button)(() => ({
  fontFamily: 'Poppins',
  backgroundColor: '#04514C',
  color: '#FFF',
  marginTop: '40px',
  fontWeight: 'bolder',
  ':hover': {
        backgroundColor: 'white',
        color: '#04514C',
        borderRadius: '0px 10px',
  },
}));


export const Appointment = () => {
    const classes = useStyles()
    const navigate = useNavigate();
  return (
    <div className={classes.container}>
      <div style={{minWidth: '20%', marginBottom:'50px'}}>
        <Sidebar  />
      </div>

      
      <div className={classes.rightDiv}>
        <div className={classes.bgImage}></div>
        <Grid className={classes.TextDiv} >
          <Typography sx={{fontWeight: 'bold', fontSize:{xs:'1.2rem', sm:'2rem', paddingBottom:'10px'}}}>Making Health <br /> Care Better Together</Typography>
          <Typography sx={{  fontSize: { xs: '0.8rem', sm: '1.2rem' } }}>Also you dry creeping beast multiply fourth abundantly our itsel signs bring our. Won form living. Whose dry you seasons divide given gathering great in whose you'll greater let livein form beast sinthete better together these place absolute right.</Typography>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <StyledButton onClick={()=>navigate('/book_appointment')}>Make An Appointment</StyledButton>
            </Grid>
            <Grid item xs={12} sm={5}>
              <StyledButton onClick={()=>navigate('/view_appointment')}>View Appointment</StyledButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
          
      
    
          
         
    </div>
  )
}
