import { useState, useEffect, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../api/axios';
import { makeStyles } from '@material-ui/styles';
import {TextField, Typography,  Grid, Button, MenuItem, Select, InputLabel} from '@mui/material';
import Navbar from '../Navbar';
import Footer from '../Footer';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { DatePicker } from 'react-responsive-datepicker'
// import 'react-responsive-datepicker/dist/index.css'

import { useImmerReducer } from "use-immer";

// context
import StateContext from '../../context/StateContext';
import DispatchContext from '../../context/DispatchContext';

const useStyles = makeStyles({
  container: {
    margin: '30% auto 20px',
    width: '100%',
    backgroundColor: '##F5F5F5',
    display: 'block',
    padding: '0',
    zIndex:'-1000',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        margin: '10% auto',
        padding:' 0',
        width: '70%',
        display: 'flex',
        justifyContent: 'space-around',
      // height:'75%',
    },
  },

  imageDiv: {
    // display:'none',
    width: '90%',
    margin: '0 auto 20px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      // display: 'block',
      margin: '0',
      width: '40%',
      
    },
  },

  image: {
    width: '100%',
    height:'60vh',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '0',
      width: '100%',
      height:'90vh',
    },
  },

  form: {
    width: '90%',
    margin: '0 auto 20px',
    backgroundColor: '#FFFFFF',
    padding: '10px 10px 30px',
    // zIndex:'1',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '45%',
        padding:'10px 30px 30px',
        margin: '0',
        // zIndex:'1',
    },
  },

  label: {
    fontSize: '20px',
    width: '100%',

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '20px',
    },
  },

  inputDiv: {
    margin:'20px auto 0',
    width: '90%',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      
    },
  },

  input: {
    width: '100%',
    // height: '40px',
    borderRadius: '10px',
    // paddingLeft: '10px',
    fontSize: '16px',
    border: '0.5px solid #04514C',
    // outline: '0.5px solid #04514C',
    margin: '5px 0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
      // height: '40px',
      fontSize: '20px',
      margin: '5px 0 10px',
    },
  },

  buttonDiv: {
    width: '90%',
    margin: '0 auto',
  },

  RegisterButton: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: 'white',
    margin: '20px 0',
    // fontWeight: 'bolder',
    backgroundColor: '#04514C',
    width: '100%',
    height: '40px',
    borderRadius: '5px',
    border:'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: '#04514C',
    border:'0.5px solid #04514C',
    },

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: '25px',
    margin: '20px 0',
    width: '100%',
    height: '50px',
  },
  },

    LoginButton: {
        fontSize: '20px',
        fontFamily: 'Poppins',
        color: '#04514C',
        margin: '20px 0',
        // fontWeight: 'bolder',
        backgroundColor: '#FFFFFF',
        width: '100%',
        height: '40px',
        borderRadius: '5px',
        border: '0.5px solid #04514C',
        '&:hover': {
            backgroundColor: '#04514C',
            color: '#FFFFFF',
        },
    },

    datepicker: {
        width: '100%',
        height: '50px',
        border: '0.1px solid gray',
        borderRadius: '5px',
        zIndex: '1000',
        padding:'0 10px',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key

        },
    },

})



export default function UpdateAppoinment({ appointmentData }) {

  const GlobalState = useContext(StateContext)

  const navigate = useNavigate();

    const classes = useStyles()

  const initialState = {
    physician: appointmentData.physician,
    timeslot: appointmentData.timeslot.value,
    purpose: appointmentData.purpose,
    date: new Date(appointmentData.date),
    sendRequest: 0,
    serverError: "",
    physicianError: {
      errorMessage: "",
      hasError: false
    },
    purposeError: {
      errorMessage: "",
      hasError: false
    },
    timeError: {
      errorMessage: "",
      hasError: false
    },
    dateError: {
      errorMessage: "",
      hasError: false
    },
  }

  const reducerFunction = (draft, action) => {
    switch (action.type) {
      case "changePhysician":
        draft.physician = action.physicianValue
        draft.physicianError.hasError = false
        draft.physicianError.errorMessage = ""
        break;
      case "changeTime":
        draft.timeslot = action.timeValue
        draft.timeError.hasError = false
        draft.timeError.errorMessage = ""
        break;
      case "changePurpose":
        draft.purpose = action.purposeValue
        draft.purposeError.hasError = false
        draft.purposeError.errorMessage = ""
        break;
      case "catchDate":
        draft.date = (action.dateValue)
        draft.dateError.hasError = false
        draft.dateError.errorMessage = ""
        break;
      case "changeSendRequest":
        draft.sendRequest = draft.sendRequest + 1
        break;
      case "catchServerError":
        draft.serverError = "The physician has already been booked for that time and date. Kindly select another time or date."
        break;
      case "catchPhysicianError":
        if (draft.physician === "") {
          draft.physicianError.hasError = true
          draft.physicianError.errorMessage = "Please select a physician"
        }
        break;
      case "catchPurposeError":
        if (draft.purpose === "") {
          draft.purposeError.hasError = true
          draft.purposeError.errorMessage = "Briefly explain why you want to see a doctor"
        }
        break;
      case "catchTimeError":
        if (draft.timeslot === "") {
          draft.timeError.hasError = true
          draft.timeError.errorMessage = "Select an appointment time"
        }
        break;
      case "catchDateError":
        draft.dateError.hasError = true
        draft.dateError.errorMessage = "Select an appointment date"
        break;
      default:
        break;
    }
  }

  const [state, dispatch] = useImmerReducer(reducerFunction, initialState)
  

  // function that handles the form submission.
  const handleSubmit = async (e) => {
    e.preventDefault()

    if(!state.physician.hasError && !state.purpose.hasError && !state.timeslot.hasError && state.date !== ""){
      dispatch({type: "changeSendRequest"})
    }
  }


  // Use effect that handles the form submission
  useEffect(() => {
    if (state.sendRequest){
      const UpdateAppointment = async () => {
        const formData = new FormData();

        formData.append('physician', state.physician)
        formData.append('purpose', state.purpose)
        formData.append('timeslot', state.timeslot)
        formData.append('date', `${state.date.getFullYear()}-${state.date.getMonth()+1}-${state.date.getDate()}`)
          
        try {
          await axiosInstance.put(`appointments/${appointmentData.id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${GlobalState.userAccessToken}`
            }
          })
          navigate(0)
        } catch (error) {
          if (error.response.data.non_field_errors) {
            dispatch({ type: "catchServerError" })
          }
        }
      }
      
      UpdateAppointment()

    }
    
  }, [state.sendRequest]);


    const physicians = ['Dr Kelly', 'Dr Andrew', 'Dr Charles']
    const timeslots = ['09:00 – 09:30', '10:00 – 10:30', '11:00 – 11:30', '12:00 – 12:30', '13:00 – 13:30', '14:00 – 14:30', '15:00 – 15:30', '16:00 – 16:30', '17:00 – 17:30']


  return (
    <div>

      <div className={classes.container}>
     

        <form className={classes.form} onSubmit={handleSubmit}>
          {/* <h2 style={{textAlign:'center',}}>Profile</h2> */}
          
        <Grid item container style={{ marginTop: '1rem' }}>
          <TextField
            id="outlined-select"
            select
            fullWidth
            label="Physician"
            defaultValue=""
            value={state.physician}
            onChange={(e)=>dispatch({type:"changePhysician", physicianValue: e.target.value})}
            onBlur={(e) => dispatch({ type: "catchPhysicianError", physicianValue: e.target.value })}
            error={state.physicianError.hasError ? true : false}
            helperText={state.physicianError.errorMessage}
          >
            {physicians.map((physician, index) => (
              <MenuItem key={index} value={physician}>
                {physician}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item container style={{ marginTop: '1rem' }}>
          <TextField
            fullWidth
            id="outlined-multiline-flexible"
            label="Purpose of Visit"
            multiline
            maxRows={12}
            value={state.purpose}
            onChange={(e) => dispatch({ type: "changePurpose", purposeValue: e.target.value })}
            onBlur={(e) => dispatch({ type: "catchPurposeError", purposeValue: e.target.value })}
            error={state.purposeError.hasError ? true : false}
            helperText={state.purposeError.errorMessage}
          />
        </Grid>
          
        <Grid item container style={{ marginTop: '1rem' }}>
          <TextField
            id="outlined-select"
            select
            fullWidth
            label="Time"
            defaultValue=""
            onBlur={(e) => dispatch({ type: "catchTimeError", timeValue: e.target.value })}
            error={state.timeError.hasError ? true : false}
            helperText={state.timeError.errorMessage}
            onChange={(e)=>dispatch({type:"changeTime", timeValue: e.target.value})}
          >
            {timeslots.map((timeslot, index) => (
              <MenuItem key={index} value={index}>
                {timeslot}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
                  
        <Grid item container xs = {12} sx={{marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto'}} >
            <DatePicker placeholderText='Date' value={state.date} dateFormat="yyyy-MM-dd" selected={state.date} onChange={(date) => dispatch({ type: 'catchDate', dateValue: date })} minDate={new Date()} className={classes.datepicker} popperPlacement="top" />
        </Grid>
          
                
        <div style={{display:'flex', gap:'20px'}}>
          <Grid item container xs = {12} sx={{marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto', }} className={classes.RegisterButton}>
            <Button  fullWidth type='submit' >Update</Button>
          </Grid>
          
          <Grid item container xs={12} sx={{ marginTop: '1rem', marginLeft: 'auto', marginRight: 'auto',  }} className={classes.RegisterButton}>
            <Button  fullWidth type='submit' onClick={()=>navigate(`/single_appointment/${appointmentData.id}`)}>Cancel</Button>
          </Grid>
        </div>

      </form>

     

       

      </div>

    </div>

   
  );
}

