import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';



export default function AppointmentCard({ image, body, title }) {

  return (
    <Card sx={{ maxWidth: {xs:300, sm:300}, margin: {xs:'20px auto', sm:'0'}, borderRadius: '20px', boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',}}>
      <CardHeader sx={{marginLeft: '40%'}}
        avatar={
            <Avatar sx={{ bgcolor: '#04514C' }}>
                {image}
            </Avatar>
        }
        
      />
      
        <CardContent>
            <Typography gutterBottom variant="body" style={{ fontWeight: 'bold', fontSize: '16px' }} component="div">
                {title}
            </Typography>
            <Typography variant="body2" color="#6E6D6D">
                {body}
            </Typography>
        </CardContent>
    </Card>
  );
}

