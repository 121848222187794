import {useState} from 'react'
import { useNavigate} from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Button from '@mui/material/Button';
import logo from '../assets/logo.png'
import { styled } from '@mui/material/styles';
import { scaleDown as Menu } from 'react-burger-menu'



const useStyles = makeStyles({
    container: {
        // backgroundColor: '#AFE1AF',
        width: '100%',
        padding: '0',
        display: 'flex',
        justifyContent: 'space-between',
        // boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '100%',  
        padding: '10px 20px 0',   
        // backgroundColor: '#fff',
        // minHeight:'100vh',
        },
    },

    headerImage: {
        margin: '10px 0 0',
        width: '70px',
        ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '100px',
        margin: '0 0 20px'
        },
  },

  bigScreen: {
    // backgroundColor: '#fff',
    width: '100%',
    padding: '10px 20px 0',
    display: 'none',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '20%',
        display: 'block',
        
        // minHeight:'110vh',
    },
    },
  
  smallScreen: {
    backgroundColor: '#fff',
    width:'100%',
    position: 'fixed',
    margin: '0',
    zIndex:'1000',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        display: 'none',
    },
    },
  

})




const Sidebar = () => {
    const classes = useStyles()
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);

 


    function handleStateChange(state) {
        setMenuOpen(state.isOpen);
    }

    

  return (
      <div className={classes.container}>
          <div className={classes.bigScreen}>
            <div style={{ width:'100%'}}><Button onClick={() => navigate('/')} color="inherit"><img className={classes.headerImage} src={logo} alt="logo" /></Button></div>
            <div onClick={() => navigate('/dashboard')} style={{display: 'flex', cursor: 'pointer', padding: '20px 10px 10px'}}>
                <i className="fa fa-qrcode" aria-hidden="true" style={{fontSize:'20px'}}></i>
                <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Dashboard</p>
            </div>
              
            {/* <div onClick={() => navigate('/')} style={{display: 'flex', cursor: 'pointer', padding: '10px'}}>
                <i className="fa fa-commenting-o" aria-hidden="true" style={{fontSize:'20px'}}></i>
                <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Messages</p>
            </div> */}
              
            <div onClick={() => navigate('/appointment')} style={{display: 'flex', cursor: 'pointer', padding: '10px'}}>
                <i className="fa fa-clock-o" aria-hidden="true" style={{fontSize:'20px'}}></i>
                <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Appointment</p>
            </div>
              
            <div onClick={() => navigate('/get_pharmacy_nearby')} style={{display: 'flex', cursor: 'pointer', padding: '10px'}}>
                <i className="fa fa-hospital-o" aria-hidden="true" style={{fontSize:'20px'}}></i>
                <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Pharmacy</p>
            </div>
              
              <hr style={{width:'12rem', border: '1px solid #04514C' }} />
              
            {/* <div onClick={() => navigate('/')} style={{display: 'flex', cursor: 'pointer', padding: '30px 10px 10px'}}>
                <i className="fa fa-cog" aria-hidden="true" style={{fontSize:'20px'}}></i>
                <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Settings</p>
              </div>
               */}
            <div onClick={() => navigate('/')} style={{display: 'flex', cursor: 'pointer', padding: '10px'}}>
                <i className="fa fa-sign-out" aria-hidden="true" style={{fontSize:'20px'}}></i>
                <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Exit</p>
            </div>
          </div>
          
          <div className={classes.smallScreen} >
            <Button onClick={() => navigate('/')} color="inherit" ><img className={classes.headerImage} src={logo} alt="logo" /></Button>
            <Menu width={250} isOpen={menuOpen} onStateChange={state => handleStateChange(state)} right>
                  {/* <Button onClick={() => navigate('/')} style={{color:'#04514C',}}><img className={classes.headerImage} src={logo} alt="logo" /></Button> */}
                <div onClick={() => navigate('/dashboard')} style={{display: 'flex', cursor: 'pointer', padding: '20px 10px 10px'}}>
                    <i className="fa fa-qrcode" aria-hidden="true" style={{fontSize:'20px'}}></i>
                    <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Dashboard</p>
                </div>
             
                <div onClick={() => navigate('/appointment')} style={{display: 'flex', cursor: 'pointer', padding: '10px'}}>
                    <i className="fa fa-clock-o" aria-hidden="true" style={{fontSize:'20px'}}></i>
                    <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Appointment</p>
                </div>
              
                <div onClick={() => navigate('/get_pharmacy_nearby')} style={{display: 'flex', cursor: 'pointer', padding: '10px'}}>
                    <i className="fa fa-hospital-o" aria-hidden="true" style={{fontSize:'20px'}}></i>
                    <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Pharmacy</p>
                </div>
              
                <hr style={{ border: '1.5px solid #04514C' }} />
{/*                 
                <div onClick={() => navigate('/')} style={{display: 'flex', cursor: 'pointer', padding: '30px 10px 10px'}}>
                    <i className="fa fa-cog" aria-hidden="true" style={{fontSize:'20px'}}></i>
                    <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Settings</p>
                </div> */}
                
                <div onClick={() => navigate('/')} style={{display: 'flex', cursor: 'pointer', padding: '10px'}}>
                    <i className="fa fa-sign-out" aria-hidden="true" style={{fontSize:'20px'}}></i>
                    <p style={{color:'#04514C', fontSize:'18px', padding: '0 10px'}}>Exit</p>
                </div>
                  
            </Menu>
          </div>
        </div>

  )
}

export default Sidebar


