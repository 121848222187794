import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axios';
import { scaleDown as Menu } from 'react-burger-menu'
import {makeStyles} from '@material-ui/styles';
import { AppBar, Box, Toolbar, Button } from "@mui/material"
import logo from './assets/logo.png'
import { styled } from '@mui/material/styles';
import StateContext from "../context/StateContext"
import DispatchContext from "../context/DispatchContext"


const useStyles = makeStyles({

  header: {
        backgroundColor: '#ffffff',
        border: '1px solid #2F845B',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        height: '70px',
        color: '#000000',  
        display: 'flex',
        justifyContent: 'start',
	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
        justifyContent: 'space-evenly',
	  },
    },

    headerMenu: {
        backgroundColor: '#ffffff',  
	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
       
	  },
    },

    headerImage: {
        width: '70px',
	['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
       width: '100px'
	  },
    }
})

const StyledButton = styled(Button)(() => ({
    fontFamily: 'Poppins',
    color: '#04514C',
    marginTop: '20px',
    fontWeight: 'bolder',
  ':hover': {
        backgroundColor: 'white',
        color: '#04514C',
        width: '120px',
        borderRadius: '0px 10px',
  },
}));

const StyledBigButton = styled(Button)(() => ({
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    color: 'black',
    fontWeight: 'bold',
    fontSize: '20px',
  ':hover': {
        backgroundColor: 'white',
        color: '#04514C',
        width: '150px',
        borderRadius: '0px 10px',
  },
}));

    
const Navbar = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const GlobalState = useContext(StateContext)
    const GlobalDispatch = useContext(DispatchContext)

    const {userIsLogged} = GlobalState
    

    // function that help to navigate each routes.
    function handleNavigation(e) {
        e.preventDefault()
        
        navigate(`/${e.target.value}`)
        // This can be used to close the menu, e.g. when a user clicks a menu item
        setMenuOpen(false)
    }

    function handleStateChange(state) {
        setMenuOpen(state.isOpen);
    }

    async function handleLogout() {
        const confirmLogout = window.confirm("Are you sure you want to logout");
        if (confirmLogout) {
            try {
                await axiosInstance.post("auth/logout/", {
                    refresh_token: localStorage.getItem("refresh_token")
                })
                GlobalDispatch({ type: "logout" })
                axiosInstance.defaults.headers["Authorization"] = null;
                navigate("/login")
            } catch (error) {
                console.log(error)
            }
        }
    }

   
    return (
   
       <Box sx={{ flexGrow: 1}}>
            <AppBar component='nav' >
                
                {/* Menu for the mobile screen */}
                <Menu  width={ 180 } isOpen={menuOpen} onStateChange={state => handleStateChange(state)} right>
                    {/* <StyledButton sx={{marginTop: '50px'}} onClick={handleNavigation} variant="text" value='about'>About</StyledButton> */}
                    <StyledButton onClick={handleNavigation} variant="text" value='services'>Services</StyledButton>
                    <StyledButton onClick={handleNavigation} variant="text" value='contact'>Contact</StyledButton>
                    {userIsLogged ? 
                        <>
                            <StyledButton onClick={handleNavigation} variant="text" value='dashboard'>Dashboard
                            </StyledButton>

                            <StyledButton onClick={handleLogout} variant="text" value='logout'>Logout
                            </StyledButton>    
                        </> :
                        <>
                            <StyledButton onClick={handleNavigation} variant="text" value='login'>Login</StyledButton>
                            <StyledButton sx={{backgroundColor: '#04514C', color:'#FFF', borderRadius: '0px 10px',}} onClick={handleNavigation} variant="contained" value='register'>Register</StyledButton>
                        </>
                    }
                </Menu>
                

                {/* Menu for the desktop view */}
                <Toolbar className = {classes.header}>
                    <Button onClick={()=>navigate('/')} color="inherit"><img className={classes.headerImage} src={logo} alt="logo"/></Button>
                    {/* <StyledBigButton onClick={handleNavigation} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, marginLeft:'150px' }} variant="text" value='about'>About</StyledBigButton> */}
                    <StyledBigButton onClick={handleNavigation} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} variant="text" value='services'>Services</StyledBigButton>
                    <StyledBigButton onClick={handleNavigation} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} variant="text" value='contact'>Contact</StyledBigButton>

         
                    {userIsLogged ?
                        <>
                            <StyledBigButton onClick={handleNavigation} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} variant="text" value = "dashboard">Dashboard</StyledBigButton>
                            <StyledBigButton sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} variant="text" value="logout" onClick={handleLogout}>Logout</StyledBigButton>
                        </>
                         : 
                        
                        <>
                            <StyledBigButton onClick={handleNavigation} sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }} variant="text" value="login">Login</StyledBigButton>

                            <StyledBigButton onClick={handleNavigation} sx={{ display: { xs: 'none', sm: 'none', md: 'block' }, backgroundColor:'#04514C', color:'#fff',borderRadius: '0px 10px', }} variant="contained" value='register'>Register</StyledBigButton>
                        </>
                        
                    }
                
                    
                </Toolbar>
            </AppBar>
        </Box>

  )
}

export default Navbar