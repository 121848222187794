import axios from 'axios';
const BASE_URL = 'https://clinicapp.nl/';

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    timeout: 50000,
    headers: {
        Authorization: localStorage.getItem('access_token') ? 'Bearer ' + localStorage.getItem('access_token') : null,
        'Content-Type': 'application/json',
        accept: 'application/json',
    }
})


axiosInstance.interceptors.response.use(response => {
    return response;
}, async function (error) {
    const originalRequest = error.config;

    if (typeof error.response === 'undefined') {
        alert(`A server/network error occurred. Looks like CORS might be the problem. Sorry about this - we will get it fixed shortly.`)
        return Promise.reject(error)
    }

    if (error.response.status === 401 && originalRequest.url === `${BASE_URL}auth/api/refresh/`) {
        window.location.href = '/login/';
        return Promise.reject(error)
    }

    if (error.response.data.code === 'token_not_valid' && error.response.status === 401 && error.response.statusText === 'Unauthorized') {
        const refreshToken = localStorage.getItem('refresh_token');

        if (refreshToken) {
            const tokenParts = JSON.parse(window.atob(refreshToken.split('.')[1]));

            const now = Math.ceil(Date.now() / 1000)
            console.log(tokenParts.exp);

            if (tokenParts.exp > now) {
                return axiosInstance
                    .post('/auth/api/refresh/', { refresh: refreshToken })
                    .then((response) => {
                        localStorage.setItem('access_token', response.data.access);
                        localStorage.setItem('refresh_token', response.data.refresh);

                        axiosInstance.defaults.headers['Authorization'] =
                            'Bearer ' + response.data.access;
                        originalRequest.headers['Authorization'] =
                            'Bearer ' + response.data.access;

                        return axiosInstance(originalRequest);
                    })
                    .catch((err) => {
                        console.log(err);
                    });

            } else {
                console.log('Refresh token is expired', tokenParts.exp, now);
                localStorage.clear();
                
                setTimeout(() => {
                    window.location.href = '/login/';
                }, 5000);
            }
        
        } else {
            console.log('Refresh token not available.');
            localStorage.clear();
            setTimeout(() => {
                    window.location.href = '/login/';
                }, 5000);
        }
    }
    localStorage.clear();
    // setTimeout(() => {
    //     window.location.href = '/login/';
    // }, 5000);
    // specific error handling done elsewhere
    return Promise.reject(error);
}

);


export default axiosInstance
