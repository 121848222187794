import pic1 from "./pic1.png"
import pic2 from "./pic2.png"
import pic3 from "./pic3.png"
import pic4 from "./pic4.png"

export const Data = [
    {
        body : 'Connect with Affordable healthcare professionals around you and get the best treatment.',
        link : '/about',
        image : pic1
    },
    {
        body : 'Connect with Affordable healthcare professionals around you and get the best treatment.',
        link : '/about',
        image : pic2
    },
    {
        body : 'Connect with Affordable healthcare professionals around you and get the best treatment.',
        link : '/about',
        image : pic3
    },
    {
        body : 'Connect with Affordable healthcare professionals around you and get the best treatment.',
        link : '/about',
        image : pic4
    },

]

