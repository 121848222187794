
import * as React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import Navbar from './Navbar';
import Footer from './Footer';


const useStyles = makeStyles({
  container: {
    margin: '0 auto 20px',
    width: '100%',
    backgroundColor: '##F5F5F5',
    display: 'block',
    padding:'100px 0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '30px auto',
      
      width: '80%',
      display: 'flex',
      justifyContent:'space-around',
      // height:'75%',
    },
  },

  imageDiv: {
    // display:'none',
    width: '90%',
    margin: '0 auto 20px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      // display: 'block',
      margin: '0',
      width: '40%',
      
    },
  },

  form: {
    width: '90%',
    margin: '0 auto 20px',
    backgroundColor: '#FFFFFF',
    padding:'10px 0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      margin: '0',
      width: '50%',
      padding:'10px 30px 30px',
    },
  },

  label: {
    fontSize: '20px',
    width: '100%',

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      fontSize: '20px',
    },
  },

  inputDiv: {
    margin:'20px auto 0',
    width: '90%',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      
    },
  },

  input: {
    width: '100%',
    height: '40px',
    borderRadius: '10px',
    // paddingLeft: '10px',
    fontSize: '16px',
    border: '0.5px solid #04514C',
    outline: '0.5px solid #04514C',
    margin: '5px 0',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
      width: '100%',
      height: '40px',
      fontSize: '20px',
      margin: '5px 0 10px',
    },
  },

  buttonDiv: {
    width: '90%',
    margin: '0 auto',
  },

  RegisterButton: {
    fontSize: '20px',
    fontFamily: 'Poppins',
    color: 'white',
    margin: '20px 0',
    // fontWeight: 'bolder',
    backgroundColor: '#04514C',
    width: '100%',
    height: '40px',
    borderRadius: '10px',
    border:'none',
  '&:hover': {
    backgroundColor: '#FFFFFF',
    color: '#04514C',
    border:'0.5px solid #04514C',
    },

    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: '25px',
    margin: '20px 0',
    width: '100%',
    height: '50px',
  },
  },

  contactPar: {
    fontSize: '18px',
    ['@media (min-width:800px)']: { // eslint-disable-line no-useless-computed-key
    fontSize: '25px',
    
  },
  },

})



export default function Contact() {

  const classes = useStyles()


  return (
    <div>
      <Navbar />
      <div className={classes.container}>
      <div className={classes.imageDiv}>
        <div>
          <h1>Phone Number</h1>
          <p className={classes.contactPar}>Customer Service</p>
          <p className={classes.contactPar}>1 (914) 405-4239</p>
          <p className={classes.contactPar}>24 hours a day, 365 days a year</p>
        </div>

        <div>
          <h1>Online Help</h1>
          <p className={classes.contactPar}>Live Chat*</p>
          <p className={classes.contactPar}>Mon - Fri, 9:00am - 8:00pm, ET</p>
          <Link to='/login' className={classes.contactPar}>Log in to clinic to start a chat</Link>
        </div>


      </div>

      <form className={classes.form}>
        <h2 style={{textAlign:'center',}}>CONTACT US</h2>
      
        <div className={classes.inputDiv}>
          <label className={classes.label} htmlFor="name">Name</label>
          <input className={classes.input} type="text" required id='fName'/>
        </div>

        <div className={classes.inputDiv}>
          <label className={classes.label} htmlFor="lName">Phone</label>
          <input className={classes.input} type="text" required id='lname'/>
        </div>

        <div className={classes.inputDiv}>
          <label className={classes.label} htmlFor="email">Email</label>
          <input className={classes.input} type="email" required id='email'/>
        </div>

        <div className={classes.inputDiv}>
          <label className={classes.label} htmlFor="email">Message</label>
          {/* <input className={classes.input} type="email" required id='email'/> */}
          <textarea className={classes.inputDiv} style={{ width: '100%', borderRadius: '10px', height:'100px' }}></textarea>
          {/* <textarea name="" id="" cols="30" rows="10"></textarea> */}
        </div>

        <div className={classes.buttonDiv}>
          <button className={classes.RegisterButton}>Send Message</button>
        </div>

      </form>

      </div>
      <Footer/>
    </div>
  );
}